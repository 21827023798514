<!-- <div class="container course-detail">
    <div class="box-default3 w-100 shadow-lg">
      <h4>B.Ed. (Two Year Duration)</h4>
      <hr class="pb-1 rounded">
      <div class="row">
        <div class="col-md-12 col-xl-6 mb-3">
          <div class="card">
            <div class="face face1 text-center mx-auto">
                <div class="content">
                    <img src="/assets/svg/courses/38.svg">
                    <h3>B.Ed.</h3>
                </div>
            </div>
            <div class="face face2 mx-auto">
                <div class="content">
                    <p>B.Ed. </p>
                    <p>Approved from NCTE</p>
                        <a class="btn btn-block" (click)="setModal(0)" data-toggle="modal" data-target="#courseModel">Check Eligibility</a>
                </div>
            </div>
        </div>
        </div>
      </div>
    </div>
    
    
   
    
  </div>
  <div class="container course-detail mt-5">
    <div class="box-default3 w-100 shadow-lg">
      <h4>B.P.Ed. (Two Year Duration)</h4>
      <hr class="pb-1 rounded">
      <div class="row">
        <div class="col-md-12 col-xl-6 mb-3">
          <div class="card">
            <div class="face face1 text-center mx-auto">
                <div class="content">
                    <img src="/assets/svg/courses/39.svg">
                    <h3>B.P.Ed.</h3>
                </div>
            </div>
            <div class="face face2 mx-auto">
                <div class="content">
                    <p>B.P.Ed.</p>
                        <a class="btn btn-block" (click)="setModal(1)" data-toggle="modal" data-target="#courseModel">Check Eligibility</a>
                </div>
            </div>
        </div>
        </div>
       
        
      </div>
    </div>
    
   
   
    
  </div>
  
  
  
  <div class="modal fade" id="courseModel" tabindex="-1" role="dialog" aria-labelledby="courseModel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="courseModelTitle"></h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <h3 class="creteria1"></h3>
          <p class="title"></p>
          <p class="font-weight-font detail1">Educational Qualification:</p>
          <p class="detail11"></p>
          <div class="bped">
            <ol class="sem1b">
              <li class="sem1b1"></li>
              <li  class="sem1b2"></li>
              <li class="sem1b3"></li>
              <li class="sem1b4"></li>
              <li class="sem1b5"></li>
              <li class="sem1b6"></li>
            </ol>
            <p class="font-weight-font detail1">Note :</p>
            <ol class="note">
              <li class="note1"></li>
              <li class="note1"></li>
            </ol>
            <ul class="detail2">
              <p class="font-weight-bold">Physical Efficiency Test:</p>
              <li class="detail21"></li>
            </ul>
            <div class="po-pso">
          
            </div>
          </div>
         
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          
        </div>
      </div>
    </div>
  </div> -->

<div id="education">
  <div class="container-fluid">
    <div class="row">

      <div class="col-md-3">
        <nav id="secondary-navigation">
          <!-- Header -->
          <header class="header">
            <h5>Education</h5>
          </header>
          <!-- /Header -->
          <ul>
            <li class="first">
              <a (click)="changeRoute('1')">Education</a>
            </li>
            <li class="second">
              <a (click)="changeRoute('2')">Building completion certificate</a>
            </li>
            <li class="third">
              <a (click)="changeRoute('3')">NCTE approval</a>
            </li>
            <li class="four">
              <a (click)="changeRoute('4')">KUK approval</a>
            </li>
            <li class="five">
              <a (click)="changeRoute('5')">Details of library books and journals </a>
            </li>
            <li class="six">
              <a (click)="changeRoute('6')">Land documents with building plan</a>
            </li>
            <li class="seven">
              <a (click)="changeRoute('7')">Sanctioned intake</a>
            </li>
            <li class="eight">
              <a (click)="changeRoute('8')">PAN card of the trust</a>
            </li>
            <li class="nine">
              <a (click)="changeRoute('9')">Infrastructure facilities</a>
            </li>
            <li class="ten">
              <a (click)="changeRoute('10')">Profile of principal with message</a>
            </li>
            <li class="eleven">
              <a (click)="changeRoute('11')">Faculty list</a>
            </li>
            <li class="twelve">
              <a (click)="changeRoute('12')">List of students - B.ed part -1</a>
            </li>
            <li class="thirteen">
              <a (click)="changeRoute('13')">List of students - B.ed part - 2</a>
            </li>
            <li class="fourteen">
              <a (click)="changeRoute('14')">FDR endowed</a>
            </li>
            <li class="fifteen">
              <a (click)="changeRoute('15')">FDR reserved </a>
            </li>
            <li class="sixteen">
              <a (click)="changeRoute('16')">Affidavit</a>
            </li>
            <li class="seventeen">
              <a (click)="changeRoute('17')">Minority Certificate</a>
            </li>
            <li class="eighteen">
              <a (click)="changeRoute('18')">Fee charged from students</a>
            </li>
            <li class="nineteen">
              <a (click)="changeRoute('19')">Balance sheet - 2022-23</a>
            </li>
          </ul>
        </nav>
      </div>


      <div class="col-md-9" *ngIf="showCard">
        <div class="container course-detail">
          <div class="box-default3 w-100 shadow-lg">
            <h4>B.Ed. (Two Year Duration)</h4>
            <hr class="pb-1 rounded">
            <div class="row">
              <div class="col-md-12 col-xl-6 mb-3">
                <div class="card">
                  <div class="face face1 text-center mx-auto">
                    <div class="content">
                      <img src="/assets/svg/courses/38.svg">
                      <h3>B.Ed.</h3>
                    </div>
                  </div>
                  <div class="face face2 mx-auto">
                    <div class="content">
                      <p>B.Ed. </p>
                      <p>NCTE Approved</p>
                      <a class="btn btn-block" (click)="setModal(0)" data-toggle="modal"
                      data-target="#courseModel">Check Eligibility</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>




        </div>

        <div class="container course-detail mt-5">
          <div class="box-default3 w-100 shadow-lg">
            <h4>B.P.Ed. (Two Year Duration)</h4>
            <hr class="pb-1 rounded">
            <div class="row">
              <div class="col-md-12 col-xl-6 mb-3">
                <div class="card">
                  <div class="face face1 text-center mx-auto">
                    <div class="content">
                      <img src="/assets/svg/courses/39.svg">
                      <h3>B.P.Ed.</h3>
                    </div>
                  </div>
                  <div class="face face2 mx-auto">
                    <div class="content">
                      <p>B.P.Ed.</p>
                      <p>NCTE Approved</p>
                      <a class="btn btn-block" (click)="setModal(1)" data-toggle="modal"
                        data-target="#courseModel">Check Eligibility</a>
                    </div>
                  </div>
                </div>
              </div>


            </div>
          </div>
        </div>


      </div>

      <div class="col-md-9" *ngIf="showPdf">
        <div id="sports">
          <div class="container-fluid pdf">
            <div class="row">
              <div class="col-12">
                <div class="card card-body">
                  <!-- <h5 class="card-title text-center">Awards & Recognitions</h5> -->
                  <div id="detail" class="text-center">
                    <pdf-viewer [src]="src" [render-text]="true" style="display: block;" id="list-view"></pdf-viewer>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-9" *ngIf="message">
        <div id="sports">
          <div class="container-fluid">
            <div class="row">
              <div class="col-12">
                <div class="card card-body">
                  <h5 class="card-title text-center">Profile Of Principal</h5>
                  <div class="image mt-3">
                    <img src="/assets/img/message/8.jpg" alt="Patron" class="img-fluid w-100 rounded">
                  </div>
                  <div class="col-12 col-md-12 col-lg-8 text-justify detail">
                    <h2>Dr. Pratima Sharma</h2>
                    <p class="lead">
                      It gives me immense pleasure and a feeling of accomplishment to head Guru Nanak Khalsa College,
                      Yamuna Nagar, one of the leading institutions of Haryana where students from every nook and
                      corner of the country receive education. The college has rendered stellar services in the fields
                      of
                      education, sports, culture, sustainable development, community welfare and empowerment in its
                      last fifty five years. We always try to cultivate students who not only have sound academic
                      knowledge and skills but also have a unique ethical attitude to serve the community and nation.
                      Alongside a wide array of job-oriented courses, we organise a variety of programmes to improve our
                      students' overall personality and thinking. On behalf of our management and faculty I extend a
                      very
                      warm and affectionate welcome to our new students. I am happy to tell you that your college has
                      won top honours in the fields of academics, sports and community welfare activities and soon you
                      too will be learning to contribute meaningfully to society and nation.
                    </p>
                    <p class="lead">The objective of the is college to provide career-oriented quality education through
                      innovative
                      delivery methods. The college continues to remain committed in its objectives of nurturing its
                      students with high moral values and having a deep connection with society and environment. he T
                      college At the same
                      time w
                      has been deeply committed to the propagation of patriotism and secularism.
                      e need to have a balanced perspective towards nature and economic progress. Our planet
                      Earth needs to be nurtured with love and care. We need to care for the environment which is so
                      vital
                      for our existence. At Guru Nanak Khalsa College we try to achieve this with full dedication.</p>
                    <p class="lead">I wish you all a joyful learning experience at the college!</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    </div>
  </div>


  <div class="modal fade" id="courseModel" tabindex="-1" role="dialog" aria-labelledby="courseModel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="courseModelTitle"></h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <h3 class="creteria1"></h3>
          <p class="title"></p>
          <p class="font-weight-font detail1">Educational Qualification:</p>
          <ol>
            <li class="detail11"></li>
            <li class="detail12"></li>
          </ol>
          <div class="bped">
            <ol class="sem1b">
              <li class="sem1b1"></li>
              <li class="sem1b2"></li>
              <li class="sem1b3"></li>
              <li class="sem1b4"></li>
              <li class="sem1b5"></li>
              <li class="sem1b6"></li>
            </ol>
            <p class="font-weight-font detail1">Note :</p>
            <ol class="note">
              <li class="note1"></li>
              <li class="note1"></li>
            </ol>
            <ul class="detail2">
              <p class="font-weight-bold">Physical Efficiency Test:</p>
              <li class="detail21"></li>
            </ul>
            <div class="po-pso">

            </div>
          </div>

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>

        </div>
      </div>
    </div>
  </div>