import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-aqar23-c1-one-four-two',
  templateUrl: './aqar23-c1-one-four-two.component.html',
  styleUrls: ['./aqar23-c1-one-four-two.component.scss']
})
export class Aqar23C1OneFourTwoComponent implements OnInit {
  src: string ='/assets/pdf/NAAC/AQAR/1.4.2_Curriculam feedback_2023-24.pdf';
  constructor() { }

  ngOnInit(): void {
  }

}
