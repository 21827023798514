<div id="aqar">
    <div id="showcase">
        <img src="/assets/img//showcase/11.jpeg" alt="banner" class="img-fluid w-100">
    </div>
    <div id="heading">
        <h1>NAAC</h1>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item" routerLink="/"><a href="#">Home</a></li>
                <li class="breadcrumb-item"><a href="#">NAAC</a></li>
                <li class="breadcrumb-item active" style="text-transform: capitalize;" aria-current="page">AQAR Report</li>
            </ol>
        </nav>
    </div>

    <div class="container-fluid ">
        <div class="row">
            <div class="col-md-3" *ngIf="showSideMenu">
                <nav id="secondary-navigation">
                    <!-- Header -->
                    <header class="header">
                        <h5>AQAR</h5>
                    </header>
                    <!-- /Header -->
                    <ul>
                        <!-- <li routerLinkActive="active">
                            <a (click)="checkRoute()" routerLink="/naac/aqar/2015-16">2015-2016</a>
                        </li>
                        <li routerLinkActive="active">
                            <a (click)="checkRoute()" routerLink="/naac/aqar/2016-17">2016-2017</a>
                        </li>
                        <li routerLinkActive="active">
                            <a (click)="checkRoute()" routerLink="/naac/aqar/2017-18">2017-2018</a>
                        </li>
                        <li routerLinkActive="active">
                            <a (click)="checkRoute()" routerLink="/naac/aqar/2018-19">2018-2019</a>
                        </li>
                        <li routerLinkActive="active">
                            <a (click)="checkRoute()" routerLink="/naac/aqar/2019-20">2019-2020</a>
                        </li>
                        <li routerLinkActive="active">
                            <a (click)="checkRoute()" routerLink="/naac/aqar/2020-21">2020-2021</a>
                        </li>
                        <li routerLinkActive="active">
                            <a (click)="checkRoute()" routerLink="/naac/aqar/2021-22">2021-2022</a>
                        </li>
                        <li routerLinkActive="active">
                            <a (click)="checkRoute()" routerLink="/naac/aqar/2022-23">2022-2023</a>
                        </li>
                        <li routerLinkActive="active">
                            <a (click)="checkRoute()" routerLink="/naac/aqar/2023-24">2023-2024</a>
                        </li> -->
                        <li class="first">
                            <a  (click)="changeRoute('2015')">2015-2016</a>
                        </li>
                        <li class="second">
                            <a  (click)="changeRoute('2016')">2016-2017</a>
                        </li><li class="third">
                            <a  (click)="changeRoute('2017')">2017-2018</a>
                        </li>
                        <li class="fourth">
                            <a  (click)="changeRoute('2018')">2018-2019</a>
                        </li>
                        <li class="fifth">
                            <a  (click)="changeRoute('2019')">2019-2020</a>
                        </li>
                        <li class="sixth">
                            <a  (click)="changeRoute('2020')">2020-2021</a>
                        </li>
                        <li class="seventh">
                            <a  (click)="changeRoute('2021')">2021-2022</a>
                        </li>
                        <li class="eight">
                            <a  (click)="changeRoute('2022')">2022-2023</a>
                        </li>
                        <li class="nine">
                            <a (click)="changeRoute('2023')">2023-2024</a>
                        </li>
                    </ul>
                </nav>
            </div>
            <div class="col-md-9">
                <div id="sports">
                    <div class="container-fluid pdf">
                        <div class="row">
                            <div class="col-12">
                                <div class="card shadow-lg">
                                    
                                    <h5 class="card-title text-center">AQAR</h5>

                                    <div class="container-fluid d-flex justify-content-center align-items-center h-100" id="loader" *ngIf="isLoading">
                                        <app-loader></app-loader>
                                    </div>
                                    <div id="detail" class="text-center">
                                        <pdf-viewer [src]="src" 
                                        [render-text]="true"
                                        style="display: block;"
                                        id="list-view"
                                        
                                        (on-progress)="hideLoader()" (error)="hideLoader()"></pdf-viewer>

                                </div>
                
                            </div>
                        </div>
                
                       
                    </div>
                </div>
            </div>
        </div>
            <!-- <div [ngClass]="showSideMenu ? 'col-md-9' : 'col-md-12'">
                <router-outlet></router-outlet>
            </div> -->
        </div>
    </div>
</div>