import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class AqarService {
    url = 'https://gnkc.ac.in/assets/pdf/NAAC/AQAR/';
    private c123: { title: string, link: string }[] = [
        {
            title: 'B.COM. EVS 2ND SEM.',
            link: this.url + '1. B.COM. EVS 2ND SEM..pdf'
        },
        {
            title: 'B.SC. EVS 2ND SEM.',
            link: this.url + '2. B.SC. EVS 2ND SEM..pdf'
        },
        {
            title: 'BA EVS 2ND_4TH_6TH SEM.',
            link: this.url + '3. BA EVS 2ND_4TH_6TH SEM..pdf'
        },
        {
            title: 'B.Sc. IMB_6th Sem.',
            link: this.url + '4. B.Sc. IMB_6th Sem..pdf'
        },
        {
            title: 'B.SC. SPORTS EVS 2ND SEM.',
            link: this.url + '5. B.SC. SPORTS EVS 2ND SEM..pdf'
        },
        {
            title: 'B.Com. CA EVS 2ND SEM.',
            link: this.url + '6. B.Com. CA EVS 2ND SEM..pdf'
        },
        {
            title: 'BBA 5TH SEM.',
            link: this.url + '7. BBA 5TH SEM..pdf'
        },
        {
            title: 'BCA EVS_2ND AND 6TH SEM',
            link: this.url + '8. BCA EVS_2ND AND 6TH SEM.pdf'
        },
        {
            title: 'MA SOCIAL WORK 4TH SEM.',
            link: this.url + '9. MA SOCIAL WORK 4TH SEM..pdf'
        },
        {
            title: 'BA SOCIAL WORK 2ND SEM.',
            link: this.url + '10. BA SOCIAL WORK 2ND SEM..pdf'
        },
        {
            title: 'B.COM. 3RD SEM.',
            link: this.url + '11. B.COM. 3RD SEM..pdf'
        },
        {
            title: 'MA SOCIAL WORK 2ND SEM.',
            link: this.url + '12. MA SOCIAL WORK 2ND SEM..pdf'
        },
        {
            title: 'MA PUBLIC ADMN. 4TH SEM.',
            link: this.url + '13. MA PUBLIC ADMN. 4TH SEM..pdf'
        },
        {
            title: 'M.SC. BIO TECH 4TH SEM.',
            link: this.url + '14. M.SC. BIO TECH 4TH SEM..pdf'
        },
        {
            title: 'BA SOCIAL WORK 6TH SEM.',
            link: this.url + '15. BA SOCIAL WORK 6TH SEM..pdf'
        },
        {
            title: 'BA SOCIAL WORK 4TH SEM.',
            link: this.url + '16. BA SOCIAL WORK 4TH SEM..pdf'
        },
        {
            title: 'B.COM. 5TH SEM.',
            link: this.url + '17. B.COM. 5TH SEM..pdf'
        },
        {
            title: 'B.P.ED.',
            link: this.url + '18. B.P.ED..pdf'
        },
        {
            title: 'B.ED. 1ST AND 2ND YEAR',
            link: this.url + '19. B.ED. 1ST AND 2ND YEAR.pdf'
        },

    ];

    private c322: { title: string, link: string }[] = [
        {
            title: 'Workshop cum Orientation 2.11.2023',
            link: this.url + '1_Workshop cum Orientation_2.11.2023.pdf'
        },
        {
            title: 'Entreprenurial and Professional 30.01.2024',
            link: this.url + '2_Entreprenurial and Professional_30.01.2024.pdf'
        }
    ];

    private c351: { title: string, link: string }[] = [
        {
            title: 'Indian institute of comp. edu',
            link: this.url + '1. Indian institute of comp. edu.pdf'
        },
        {
            title: 'Contrivez',
            link: this.url + '2. Contrivez.pdf'
        },
        {
            title: 'Webcom',
            link: this.url + '3. Webcom.pdf'
        },
        {
            title: 'Ek Soch Nayi Soch',
            link: this.url + '4. Ek Soch Nayi Soch.pdf'
        },
        {
            title: 'MOU Udhyam Learning , Ek Soch and GNKC',
            link: this.url + '5. MOU _Udhyam Learning , Ek Soch and GNKC.pdf'
        },
        {
            title: 'SMS Khalsa Lanana Girls College, Barara',
            link: this.url + '6. SMS Khalsa Lanana Girls College, Barara.pdf'
        },
        {
            title: 'GNG College, Yamuna Nagar',
            link: this.url + '7. GNG College, Yamuna Nagar.pdf'
        },
        {
            title: 'BABU ANANT RAM JANTA COLLEE KAUL KAITHAL',
            link: this.url + '8. BABU ANANT RAM JANTA COLLEE KAUL_KAITHAL.pdf'
        },
    ];

    private c5: { title: string, link: string }[] = [
        {
            title: 'Post matric SC Scholarship 2023-24',
            link: this.url + '1. Post matric SC Scholarship 2023-24.pdf'
        },
        {
            title: 'Post matric BC_OBC_Father less_BPL Scholarship 2023-24',
            link: this.url + '2. Post matric BC_OBC_Father less_BPL Scholarship 2023-24.pdf'
        },
        {
            title: 'Goverment fee concession for Girls Students 2023-24',
            link: this.url + '3. Goverment fee concession for Girls Students 2023-24.pdf'
        },
        {
            title: 'Goverment Scholarship for Meritorious Students 2023-24',
            link: this.url + '4. Goverment Scholarship for Meritorious Students 2023-24.pdf'
        },
        {
            title: 'Individual Scholarship for All India Inter University Level 2023-24',
            link: this.url + '5. Individual Scholarship for All India Inter University Level 2023-24.pdf'
        },
        {
            title: 'Individual Scholarship for Zonal_National_University Games_2023-24',
            link: this.url + '6. Individual Scholarship for Zonal_National_University Games_2023-24.pdf'
        },
        {
            title: 'Individual Khelo India University Game Scholarship 2023-24',
            link: this.url + '7. Individual Khelo India University Game Scholarship 2023-24.pdf'
        },
        {
            title: 'Sita Ram Jindal Foundation Scholarship_2023-24',
            link: this.url + '8. Sita Ram Jindal Foundation Scholarship_2023-24.pdf'
        },
        {
            title: 'Jamna Auto Industries Scholarship Academic 2023-24',
            link: this.url + '9. Jamna Auto Industries Scholarship Academic 2023-24.pdf'
        },
        {
            title: 'Jamna Auto Industries Scholarship for Sports Persons 2023-24',
            link: this.url + '10. Jamna Auto Industries Scholarship for Sports Persons 2023-24.pdf'
        },
        {
            title: 'Scholarship by Philanthropists 2023-24',
            link: this.url + '11. Scholarship by Philanthropists 2023-24.pdf'
        },
    ];

    private c711: { title: string, link: string }[] = [
        {
            title: 'Annual Gender Sensitization Action Plan and Activities',
            link: this.url + '7.1.1_Annual Gender Sensitization Action Plan and Activities.pdf'
        },
        {
            title: 'Facilities for Women and Safety and Security Measures',
            link: this.url + '7.1.1_Facilities for Women and Safety and Security Measures.pdf'
        }
    ];

    private c721: { title: string, link: string }[] = [
        {
            title: 'Best Practice-1 Woment Empowement',
            link: this.url + '7.2.1_Best Practice-1 Woment Empowement.pdf'
        },
        {
            title: 'Best Practic-2 Environment Conservation and Protection Practices',
            link: this.url + '7.2.1_Best Practic-2 Environment Conservation and Protection Practices.pdf'
        },
    ];

    private c731: { title: string, link: string }[] = [
        {
            title: 'Sports Policy',
            link: this.url + '7.3.1_ID1. Sports Policy.pdf'
        },
        {
            title: 'Governance Committee',
            link: this.url + '7.3.1_ID2. Governance Committee.pdf'
        },
        {
            title: 'KUK Oveall General Championships Since 1990-91',
            link: this.url + '7.3.1_ID3. KUK Oveall General Championships Since 1990-91.pdf'
        },
        {
            title: 'KUK Games Championship Position',
            link: this.url + '7.3.1_ID4. KUK Games Championship_Position.pdf'
        },
        {
            title: 'Sports Courses',
            link: this.url + '7.3.1_ID5. Sports Courses.pdf'
        },
        {
            title: 'Medal Winners and Participants (International_National_AIIU_NZIU)',
            link: this.url + '7.3.1_ID6. Medal Winners and Participants (International_National_AIIU_NZIU).pdf'
        },
        {
            title: 'List of Sports Faculty',
            link: this.url + '7.3.1_ID7. List of Sports Faculty.pdf'
        },
        {
            title: 'Games offered in the College',
            link: this.url + '7.3.1_ID8. Games offered in the College.pdf'
        },
        {
            title: 'Finances on Sports',
            link: this.url + '7.3.1_ID9. Finances on Sports.pdf'
        },
        {
            title: 'Sports Facilities',
            link: this.url + '7.3.1_ID10. Sports Facilities.pdf'
        },
        {
            title: 'Departmental Activities',
            link: this.url + '7.3.1_ID11_Departmental Activities.pdf'
        },
        {
            title: 'Scholarships',
            link: this.url + '7.3.1_ID12. Scholarships.pdf'
        },
        {
            title: 'Report on Athletic Meet',
            link: this.url + '7.3.1_ID13_Report on Athletic Meet.pdf'
        },
        {
            title: 'Sports Alumni',
            link: this.url + '7.3.1_ID14. Sports Alumni.pdf'
        },
    ];

    constructor() { }

    getC123() {
        return this.c123.slice();
    }

    getC322() {
        return this.c322.slice();
    }

    getC351() {
        return this.c351.slice();
    }

    getC5() {
        return this.c5.slice();
    }

    getC711() {
        return this.c711.slice();
    }

    getC721() {
        return this.c721.slice();
    }

    getC731() {
        return this.c731.slice();
    }
}
