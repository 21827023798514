import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-aq-twotwo-twothree',
  templateUrl: './aq-twotwo-twothree.component.html',
  styleUrls: ['./aq-twotwo-twothree.component.scss']
})
export class AqTwotwoTwothreeComponent implements OnInit {
  src: string ='/assets/pdf/AQAR_2022-23.pdf';
  constructor() { }

  ngOnInit(): void {
  }

}
