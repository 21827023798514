import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-twothree-twofour',
  templateUrl: './twothree-twofour.component.html',
  styleUrls: ['./twothree-twofour.component.scss']
})
export class TwothreeTwofourComponent implements OnInit {
@HostListener("click") onClick(){
    this.checkRoute();
  }
  fullWidth=false;
  showSideMenu = true;
  showHeading = false;
  constructor() { }

  ngOnInit(): void {
    this.checkRoute();
  }

  splitUrl(){
    let url=location.pathname.split('/');
    if(url.length === 4 || (url.length === 5 && url[4]==='extended-profile') ){
      this.showSideMenu=true;
      this.fullWidth=false;
    }
    else{
      this.showSideMenu=false;
      this.fullWidth=true;
    }
  }
  checkRoute(){
    setTimeout(()=>{
      this.splitUrl();
    })
  }
}
