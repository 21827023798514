import { Component, OnInit } from '@angular/core';
import { AqarService } from 'src/app/services/aqar/aqar.service';
declare var $: any;

@Component({
  selector: 'app-aqar23-c1-one-three-two',
  templateUrl: './aqar23-c1-one-three-two.component.html',
  styleUrls: ['./aqar23-c1-one-three-two.component.scss']
})
export class Aqar23C1OneThreeTwoComponent implements OnInit {
  // c132Url='https://gnkc.ac.in/assets/pdf/NAAC/Criterion 1/1.3.2/PROJECTS-2021-22/';
  // session: { title: string, link: string }[] = [
  //   {
  //     title: 'MA Public admn.',
  //     link: this.c132Url + '1. MA Public admn..pdf'
  //   },
  //   {
  //     title: 'M.Sc. BIO TECH.',
  //     link: this.c132Url + '2. M.Sc. BIO TECH..pdf'
  //   },
  //   {
  //     title: 'B.SC. IMB',
  //     link: this.c132Url + '3. B.SC. IMB.pdf'
  //   },
  //   {
  //     title: 'B.COM. 3rd SEM',
  //     link: this.c132Url + '4. B.COM. 3RD SEM.pdf'
  //   },
  //   {
  //     title: 'B.COM. 5TH SEM',
  //     link: this.c132Url + '5. B.COM. 5TH SEM.pdf'
  //   },
  //   {
  //     title: 'BBA',
  //     link: this.c132Url + '6. BBA.pdf'
  //   },
  //   {
  //     title: 'MSW SEM-2',
  //     link: this.c132Url + '7. MSW SEM-2.pdf'
  //   },
  //   {
  //     title: 'MSW SEM-4',
  //     link: this.c132Url + '8. MSW SEM-4.pdf'
  //   },
  //   {
  //     title: 'B.Ed 1st year',
  //     link: this.c132Url + '9. B.Ed. 1ST YEAR.pdf'
  //   },
  //   {
  //     title: 'B.Ed 2nd year',
  //     link: this.c132Url + '10. B.Ed 2nd year.pdf'
  //   },
  //   {
  //     title: 'EVS Projects',
  //     link: this.c132Url + '11. EVS Projects.pdf'
  //   },
  //   {
  //     title: 'B.SC SPORTS COMP. AWARNESS',
  //     link: this.c132Url + '12. B.SC SPORTS COMP. AWARNESS.pdf'
  //   },
  //   {
  //     title: 'B.P.ED. 2ND YEAR',
  //     link: this.c132Url + '13. B.P.ED. -2ND YEAR.pdf'
  //   },
  //   {
  //     title: 'M.SC. PHYSICS SEM-2',
  //     link: this.c132Url + '14. M.SC. PHYSICS SEM 2.pdf'
  //   },
  //   {
  //     title: 'M.SC. PHYSICS SEM-4',
  //     link: this.c132Url + '15. M.SC. PHYSICS -SEM-4.pdf'
  //   },
  //   {
  //     title: 'BA SOCIAL WORK SEM-4',
  //     link: this.c132Url + '16. B.A. SOCIAL WORK SEM-4.pdf'
  //   },
  //   {
  //     title: 'BA SOCIAL WORK SEM-6',
  //     link: this.c132Url + '17. BA SOCIAL WORK SEM-6.pdf'
  //   },

  // ];
  session: { title: string, link: string }[] = [];
  constructor(private service: AqarService) {
  }

  ngOnInit(): void {
    this.session = this.service.getC123();
  }
}
