<div class="container-fluid">
    <div class="row">
        <div class="col-md-3">
            <a routerLink="/naac/aqar/2023-24"  *ngIf="showSideMenu"> <i class="fas fa-long-arrow-alt-left"></i> Go Back</a>
            <nav id="secondary-navigation" *ngIf="showSideMenu">
                <!-- Header -->
                <header class="header">
                    <h5>CRITERION-1</h5>
                </header>
                <!-- /Header -->
                <ul>
                    <li routerLinkActive="active">
                        <a  routerLink="/naac/aqar/2023-24/criteria1/1-3-2" (click)="checkRoute()">1.3.2</a>
                    </li>
                    <li routerLinkActive="active">
                        <a  routerLink="/naac/aqar/2023-24/criteria1/1-4-2" (click)="checkRoute()">1.4.2</a>
                    </li>
                   
                </ul>
            </nav>
        </div>
        <div [ngClass]="fullWidth ? 'col-md-12' : 'col-md-9'">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>