import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-aqar23-c7',
  templateUrl: './aqar23-c7.component.html',
  styleUrls: ['./aqar23-c7.component.scss']
})
export class Aqar23C7Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
