import { Component, OnInit } from '@angular/core';
declare var $:any;
@Component({
  selector: 'app-aqar',
  templateUrl: './aqar.component.html',
  styleUrls: ['./aqar.component.scss']
})
export class AqarComponent implements OnInit {
  pdfSrc:string[]=[
    '/assets/pdf/2015_16.pdf',
    '/assets/pdf/NAAC_2016-17.pdf',
    '/assets/pdf/NAAC_2017-18.pdf',
    '/assets/pdf/NAAC_2018-19.pdf',
    '/assets/pdf/NAAC_2019-20.pdf',
    '/assets/pdf/AQAR-2020-21.pdf',
    '/assets/pdf/AQAR_2021-22.pdf',
    '/assets/pdf/AQAR_2022-23.pdf',
    '/assets/pdf/AQAR_2023-24.pdf',
  ];
  src:string;

  isLoading=true;
  showSideMenu=true;
  constructor() { }

  ngOnInit(): void {
    // this.checkRoute();
    this.changeRoute('2015');
  }

  // splitUrl(){
  //   let url=location.pathname.split('/');
  //   if(url.length === 3 || (url.length === 4 && url[3]!=='2023-24')  ){
  //     this.showSideMenu=true;
  //   }
  //   else{
  //     this.showSideMenu=false;
  //   }
  // }
  // checkRoute(){
  //   setTimeout(()=>{
  //     this.splitUrl();
  //   })
  // }

  changeRoute(year:string){
    this.isLoading=true;
    if(year=='2015'){
      $('.first').addClass('active');
      $('.second').removeClass('active');
      $('.third').removeClass('active');
      $('.fourth').removeClass('active');
      $('.fifth').removeClass('active');
      $('.sixth').removeClass('active');
      $('.seventh').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      this.src=this.pdfSrc[0];
    }
    if(year=='2016'){
      $('.second').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.fourth').removeClass('active');
      $('.fifth').removeClass('active');
      $('.sixth').removeClass('active');
      $('.seventh').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      this.src=this.pdfSrc[1];
    }
    if(year=='2017'){
      $('.third').addClass('active');
      $('.first').removeClass('active');
      $('.second').removeClass('active');
      $('.fourth').removeClass('active');
      $('.fifth').removeClass('active');
      $('.sixth').removeClass('active');
      $('.seventh').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      this.src=this.pdfSrc[2];
    }
    if(year=='2018'){
      $('.fourth').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.fifth').removeClass('active');
      $('.sixth').removeClass('active');
      $('.seventh').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      this.src=this.pdfSrc[3];
    }
    if(year=='2019'){
      $('.fifth').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.fourth').removeClass('active');
      $('.sixth').removeClass('active');
      $('.seventh').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      this.src=this.pdfSrc[4];
    }
    if(year=='2020'){
      $('.sixth').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.fourth').removeClass('active');
      $('.fifth').removeClass('active');
      $('.seventh').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      this.src=this.pdfSrc[5];
    }
    if(year=='2021'){
      $('.seventh').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.fourth').removeClass('active');
      $('.fifth').removeClass('active');
      $('.sixth').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      this.src=this.pdfSrc[6];
    }
    if(year=='2022'){
      $('.eight').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.fourth').removeClass('active');
      $('.fifth').removeClass('active');
      $('.sixth').removeClass('active');
      $('.seventh').removeClass('active');
      $('.nine').removeClass('active');
      this.src=this.pdfSrc[7];
    }
    if(year=='2023'){
      $('.nine').addClass('active');
      $('.eight').removeClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.fourth').removeClass('active');
      $('.fifth').removeClass('active');
      $('.sixth').removeClass('active');
      $('.seventh').removeClass('active');
      this.src = this.pdfSrc[8];
    }
    this.isLoading = false;
  }
  hideLoader(){
    this.isLoading=false;
  }
}
