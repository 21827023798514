import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-aqar23-c6-six-one-two',
  templateUrl: './aqar23-c6-six-one-two.component.html',
  styleUrls: ['./aqar23-c6-six-one-two.component.scss']
})
export class Aqar23C6SixOneTwoComponent implements OnInit {
  src: string ='/assets/pdf/NAAC/AQAR/6.1.2_Upload any additional information_56th annual athelatic meet.pdf';
  constructor() { }

  ngOnInit(): void {
  }

}
