import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-aqar23-c1',
  templateUrl: './aqar23-c1.component.html',
  styleUrls: ['./aqar23-c1.component.scss']
})
export class Aqar23C1Component implements OnInit {
  @HostListener("click") onClick() {
    this.checkRoute();
  }
  fullWidth = false;
  showSideMenu = false;
  constructor() { }

  ngOnInit(): void {
    this.checkRoute();
  }

  splitUrl() {
    let url = location.pathname.split('/');
    if (url.length === 5 || (url.length === 6 && (url[5] === '1-3-2' || url[5]=== '1-4-2'))) {
      this.showSideMenu = true;
      this.fullWidth = false;
    }
    else {
      this.showSideMenu = false;
      this.fullWidth = true;
    }
  }
  checkRoute() {
    setTimeout(() => {
      this.splitUrl();
    })
  }
}
