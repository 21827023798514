<div id="heading">
  <h1>RTI</h1>
  <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item" routerLink="/"><a href="#">Home</a></li>
        <li class="breadcrumb-item"><a href="#">About Us</a></li>
        <li class="breadcrumb-item active" aria-current="page">RTI</li>
      </ol>
    </nav>
</div>

<div id="rti">
  <div class="container-fluid">
      <div class="card pl-3 pr-3 text-justify ml-lg-5" >
          <h4 class="card-title text-center " >RTI </h4>
          <div class="card-body text-justify">
            <h2 class="font-weight-bold text-black">Public Information Officer (PIO)</h2>
            <h5 class="text-dark">Principal Dr. Pratima Sharma</h5>
            <h5 class="text-dark">Ph.No. 01732-221675(O)</h5>
            <h2 class="font-weight-bold text-black mt-3">First Appellate Authority</h2>
            <h5 class="text-dark">S. Balwant Singh Banga, General Secretary,</h5>
            <h5 class="text-dark">Guru Nanak Khalsa College, Yamuna Nagar            </h5>
            <h5 class="inner-lead text-dark mt-4">**The applicants may obtain the information as per RTI Act 2005 on prescribed format with the requisite fees</h5>
                  <!-- <iframe src="https://drive.google.com/file/d/1Quub898umfoqR7Cyjj_GUGYMLXxLI6ZB/preview" width="100%" height="640px" allow="autoplay"></iframe> -->
          </div>
      </div>
  </div>
</div>