import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-aq-onenine-twozero',
  templateUrl: './aq-onenine-twozero.component.html',
  styleUrls: ['./aq-onenine-twozero.component.scss']
})
export class AqOnenineTwozeroComponent implements OnInit {
  src: string ='/assets/pdf/NAAC_2019-20.pdf';
  constructor() { }

  ngOnInit(): void {
  }

}
