import { Component, OnInit } from '@angular/core';
import { AqarService } from 'src/app/services/aqar/aqar.service';

@Component({
  selector: 'app-aqar23-c5-five-one-one',
  templateUrl: './aqar23-c5-five-one-one.component.html',
  styleUrls: ['./aqar23-c5-five-one-one.component.scss']
})
export class Aqar23C5FiveOneOneComponent implements OnInit {
  session: { title: string, link: string }[] = [];
  constructor(private service: AqarService) {
  }

  ngOnInit(): void {
    this.session = this.service.getC5();
  }
}
