<div id="aqar">
    <div id="showcase">
        <img src="/assets/img//showcase/11.jpeg" alt="banner" class="img-fluid w-100">
    </div>
    <div id="heading">
        <h1>NAAC</h1>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item" routerLink="/"><a href="#">Home</a></li>
                <li class="breadcrumb-item"><a href="#">NAAC</a></li>
                <li class="breadcrumb-item active" style="text-transform: capitalize;" aria-current="page">AQAR
                </li>
            </ol>
        </nav>
    </div>
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-3">
                <!-- <a routerLink="/naac/aqar" *ngIf="showSideMenu"> <i class="fas fa-long-arrow-alt-left"></i> Go Back</a> -->
                <nav id="secondary-navigation" *ngIf="showSideMenu">
                    <!-- Header -->
                    <header class="header">
                        <h5>2023-2024</h5>
                    </header>
                    <!-- /Header -->
                    <ul>
                        <li routerLinkActive="active">
                            <a routerLink="/naac/aqar/2023-24/extended-profile" (click)="checkRoute()">EXTENDED
                                PROFILE</a>
                        </li>
                        <li routerLinkActive="active">
                            <a routerLink="/naac/aqar/2023-24/criteria1/1-3-2" (click)="checkRoute()">CRITERION-1</a>
                        </li>
                        <li routerLinkActive="active">
                            <a routerLink="/naac/aqar/2023-24/criteria2/2-7-1" (click)="checkRoute()">CRITERION-2</a>
                        </li>
                        <li routerLinkActive="active">
                            <a routerLink="/naac/aqar/2023-24/criteria3/3-2-2" (click)="checkRoute()">CRITERION-3</a>
                        </li>
                        <li routerLinkActive="active">
                            <a (click)="checkRoute()">CRITERION-4</a>
                        </li>
                        <li routerLinkActive="active">
                            <a routerLink="/naac/aqar/2023-24/criteria5/5-1-1" (click)="checkRoute()">CRITERION-5</a>
                        </li>
                        <li routerLinkActive="active">
                            <a routerLink="/naac/aqar/2023-24/criteria6/6-1-2" (click)="checkRoute()">CRITERION-6</a>
                        </li>
                        <li routerLinkActive="active">
                            <a routerLink="/naac/aqar/2023-24/criteria7/7-1-1" (click)="checkRoute()">CRITERION-7</a>
                        </li>

                    </ul>
                </nav>
            </div>
            <div [ngClass]="fullWidth ? 'col-md-12' : 'col-md-9'">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</div>