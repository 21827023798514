import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-aqar23-c5-five-one-three',
  templateUrl: './aqar23-c5-five-one-three.component.html',
  styleUrls: ['./aqar23-c5-five-one-three.component.scss']
})
export class Aqar23C5FiveOneThreeComponent implements OnInit {
  src: string ='/assets/pdf/NAAC/AQAR/5.1.3_Soft Skill_Language Lab_Yoga Health and Hygiene_ICT.pdf';
  constructor() { }

  ngOnInit(): void {
  }

}
