import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DvvComponent } from 'src/app/view/naac/dvv/dvv.component';
import { AqarComponent } from 'src/app/view/naac/aqar/aqar.component';
import { IiqaComponent } from 'src/app/view/naac/iiqa/iiqa.component';
import { NaacComponent } from 'src/app/view/naac/naac.component';
import { SsrComponent } from 'src/app/view/naac/ssr/ssr.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { LoaderModule } from '../loader/loader.module';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
import { ConclusionComponent } from 'src/app/view/naac/ssr/conclusion/conclusion.component';
import { CreteriaFiveComponent } from 'src/app/view/naac/ssr/creteria-five/creteria-five.component';
import { CreteriaFourComponent } from 'src/app/view/naac/ssr/creteria-four/creteria-four.component';
import { CreteriaOneComponent } from 'src/app/view/naac/ssr/creteria-one/creteria-one.component';
import { CreteriaSevenComponent } from 'src/app/view/naac/ssr/creteria-seven/creteria-seven.component';
import { CreteriaSixComponent } from 'src/app/view/naac/ssr/creteria-six/creteria-six.component';
import { CreteriaThreeComponent } from 'src/app/view/naac/ssr/creteria-three/creteria-three.component';
import { CreteriaTwoComponent } from 'src/app/view/naac/ssr/creteria-two/creteria-two.component';
import { ExecutiveComponent } from 'src/app/view/naac/ssr/executive/executive.component';
import { ExtendedRpofileComponent } from 'src/app/view/naac/ssr/extended-rpofile/extended-rpofile.component';
import { ProfileComponent } from 'src/app/view/naac/ssr/profile/profile.component';
import { OneOneComponent } from 'src/app/view/naac/ssr/executive/one-one/one-one.component';
import { IntroductionComponent } from 'src/app/view/naac/ssr/executive/one-one/introduction/introduction.component';
import { OneTwoComponent } from 'src/app/view/naac/ssr/executive/one-two/one-two.component';
import { OneThreeComponent } from 'src/app/view/naac/ssr/executive/one-three/one-three.component';
import { SwocComponent } from 'src/app/view/naac/ssr/executive/one-two/swoc/swoc.component';
import { CriteriaComponent } from 'src/app/view/naac/ssr/executive/one-three/criteria/criteria.component';
import { BasicInfoComponent } from 'src/app/view/naac/ssr/profile/two-one/basic-info/basic-info.component';
import { TwoOneComponent } from 'src/app/view/naac/ssr/profile/two-one/two-one.component';
import { AcademicInfoComponent } from 'src/app/view/naac/ssr/profile/two-two/academic-info/academic-info.component';
import { TwoTwoComponent } from 'src/app/view/naac/ssr/profile/two-two/two-two.component';
import { OneFourComponent } from 'src/app/view/naac/ssr/creteria-one/one-four/one-four.component';
import { COneOneComponent } from 'src/app/view/naac/ssr/creteria-one/c-one-one/c-one-one.component';
import { COneTwoComponent } from 'src/app/view/naac/ssr/creteria-one/c-one-two/c-one-two.component';
import { COneThreeComponent } from 'src/app/view/naac/ssr/creteria-one/c-one-three/c-one-three.component';
import { CTwoOneComponent } from 'src/app/view/naac/ssr/creteria-two/c-two-one/c-two-one.component';
import { TwoOneOneOneComponent } from 'src/app/view/naac/ssr/creteria-two/c-two-one/two-one-one/two-one-one-one/two-one-one-one.component';
import { TwoOneOneTwoComponent } from 'src/app/view/naac/ssr/creteria-two/c-two-one/two-one-one/two-one-one-two/two-one-one-two.component';
import { TwoOneOneComponent } from 'src/app/view/naac/ssr/creteria-two/c-two-one/two-one-one/two-one-one.component';
import { TwoOneTwoOneComponent } from 'src/app/view/naac/ssr/creteria-two/c-two-one/two-one-two/two-one-two-one/two-one-two-one.component';
import { TwoOneTwoTwoComponent } from 'src/app/view/naac/ssr/creteria-two/c-two-one/two-one-two/two-one-two-two/two-one-two-two.component';
import { TwoOneTwoComponent } from 'src/app/view/naac/ssr/creteria-two/c-two-one/two-one-two/two-one-two.component';
import { CTwoTwoComponent } from 'src/app/view/naac/ssr/creteria-two/c-two-two/c-two-two.component';
import { TwoTwoOneComponent } from 'src/app/view/naac/ssr/creteria-two/c-two-two/two-two-one/two-two-one.component';
import { TwoFiveOneComponent } from 'src/app/view/naac/ssr/creteria-two/two-five/two-five-one/two-five-one.component';
import { TwoFiveComponent } from 'src/app/view/naac/ssr/creteria-two/two-five/two-five.component';
import { TwoFourOneOneComponent } from 'src/app/view/naac/ssr/creteria-two/two-four/two-four-one/two-four-one-one/two-four-one-one.component';
import { TwoFourOneComponent } from 'src/app/view/naac/ssr/creteria-two/two-four/two-four-one/two-four-one.component';
import { TwoFourTwoOneComponent } from 'src/app/view/naac/ssr/creteria-two/two-four/two-four-two/two-four-two-one/two-four-two-one.component';
import { TwoFourTwoComponent } from 'src/app/view/naac/ssr/creteria-two/two-four/two-four-two/two-four-two.component';
import { TwoFourComponent } from 'src/app/view/naac/ssr/creteria-two/two-four/two-four.component';
import { TwoSevenOneComponent } from 'src/app/view/naac/ssr/creteria-two/two-seven/two-seven-one/two-seven-one.component';
import { TwoSevenComponent } from 'src/app/view/naac/ssr/creteria-two/two-seven/two-seven.component';
import { TwoSixOneComponent } from 'src/app/view/naac/ssr/creteria-two/two-six/two-six-one/two-six-one.component';
import { TwoSixThreeOneComponent } from 'src/app/view/naac/ssr/creteria-two/two-six/two-six-three/two-six-three-one/two-six-three-one.component';
import { TwoSixThreeTwoComponent } from 'src/app/view/naac/ssr/creteria-two/two-six/two-six-three/two-six-three-two/two-six-three-two.component';
import { TwoSixThreeComponent } from 'src/app/view/naac/ssr/creteria-two/two-six/two-six-three/two-six-three.component';
import { TwoSixTwoComponent } from 'src/app/view/naac/ssr/creteria-two/two-six/two-six-two/two-six-two.component';
import { TwoSixComponent } from 'src/app/view/naac/ssr/creteria-two/two-six/two-six.component';
import { TwoThreeComponent } from 'src/app/view/naac/ssr/creteria-two/two-three/two-three.component';
import { EpTwoOneComponent } from 'src/app/view/naac/ssr/extended-rpofile/two/ep-two-one/ep-two-one.component';
import { EpTwoTwoComponent } from 'src/app/view/naac/ssr/extended-rpofile/two/ep-two-two/ep-two-two.component';
import { OneComponent } from 'src/app/view/naac/ssr/extended-rpofile/one/one.component';
import { ThreeComponent } from 'src/app/view/naac/ssr/extended-rpofile/three/three.component';
import { TwoComponent } from 'src/app/view/naac/ssr/extended-rpofile/two/two.component';
import { EpThreeOneComponent } from 'src/app/view/naac/ssr/extended-rpofile/three/ep-three-one/ep-three-one.component';
import { EpOneOneComponent } from 'src/app/view/naac/ssr/extended-rpofile/one/ep-one-one/ep-one-one.component';
import { C3ThreeFiveOneComponent } from 'src/app/view/naac/ssr/creteria-three/c3-three-five/c3-three-five-one/c3-three-five-one.component';
import { C3ThreeFiveComponent } from 'src/app/view/naac/ssr/creteria-three/c3-three-five/c3-three-five.component';
import { C3ThreeFourOneComponent } from 'src/app/view/naac/ssr/creteria-three/c3-three-four/c3-three-four-one/c3-three-four-one.component';
import { C3ThreeFourThreeOneComponent } from 'src/app/view/naac/ssr/creteria-three/c3-three-four/c3-three-four-three/c3-three-four-three-one/c3-three-four-three-one.component';
import { C3ThreeFourThreeComponent } from 'src/app/view/naac/ssr/creteria-three/c3-three-four/c3-three-four-three/c3-three-four-three.component';
import { C3ThreeFourTwoComponent } from 'src/app/view/naac/ssr/creteria-three/c3-three-four/c3-three-four-two/c3-three-four-two.component';
import { C3ThreeFourComponent } from 'src/app/view/naac/ssr/creteria-three/c3-three-four/c3-three-four.component';
import { C3ThreeOneOneComponent } from 'src/app/view/naac/ssr/creteria-three/c3-three-one/c3-three-one-one/c3-three-one-one.component';
import { ThreeOneOneOneComponent } from 'src/app/view/naac/ssr/creteria-three/c3-three-one/c3-three-one-one/three-one-one-one/three-one-one-one.component';
import { C3ThreeOneComponent } from 'src/app/view/naac/ssr/creteria-three/c3-three-one/c3-three-one.component';
import { C3ThreeThreeOneOneComponent } from 'src/app/view/naac/ssr/creteria-three/c3-three-three/c3-three-three-one/c3-three-three-one-one/c3-three-three-one-one.component';
import { C3ThreeThreeOneComponent } from 'src/app/view/naac/ssr/creteria-three/c3-three-three/c3-three-three-one/c3-three-three-one.component';
import { C3ThreeThreeTwoOneComponent } from 'src/app/view/naac/ssr/creteria-three/c3-three-three/c3-three-three-two/c3-three-three-two-one/c3-three-three-two-one.component';
import { C3ThreeThreeTwoComponent } from 'src/app/view/naac/ssr/creteria-three/c3-three-three/c3-three-three-two/c3-three-three-two.component';
import { C3ThreeThreeComponent } from 'src/app/view/naac/ssr/creteria-three/c3-three-three/c3-three-three.component';
import { C3ThreeTwoOneComponent } from 'src/app/view/naac/ssr/creteria-three/c3-three-two/c3-three-two-one/c3-three-two-one.component';
import { C3ThreeTwoTwoComponent } from 'src/app/view/naac/ssr/creteria-three/c3-three-two/c3-three-two-two/c3-three-two-two.component';
import { ThreeTwoTwoOneComponent } from 'src/app/view/naac/ssr/creteria-three/c3-three-two/c3-three-two-two/three-two-two-one/three-two-two-one.component';
import { C3ThreeTwoComponent } from 'src/app/view/naac/ssr/creteria-three/c3-three-two/c3-three-two.component';
import { C4FourFourOneOneComponent } from 'src/app/view/naac/ssr/creteria-four/c4-four-four/c4-four-four-one/c4-four-four-one-one/c4-four-four-one-one.component';
import { C4FourFourOneComponent } from 'src/app/view/naac/ssr/creteria-four/c4-four-four/c4-four-four-one/c4-four-four-one.component';
import { C4FourFourComponent } from 'src/app/view/naac/ssr/creteria-four/c4-four-four/c4-four-four.component';
import { C4FourOneOneComponent } from 'src/app/view/naac/ssr/creteria-four/c4-four-one/c4-four-one-one/c4-four-one-one.component';
import { C4FourOneTwoOneComponent } from 'src/app/view/naac/ssr/creteria-four/c4-four-one/c4-four-one-two/c4-four-one-two-one/c4-four-one-two-one.component';
import { C4FourOneTwoComponent } from 'src/app/view/naac/ssr/creteria-four/c4-four-one/c4-four-one-two/c4-four-one-two.component';
import { C4FourOneComponent } from 'src/app/view/naac/ssr/creteria-four/c4-four-one/c4-four-one.component';
import { C4FourThreeOneComponent } from 'src/app/view/naac/ssr/creteria-four/c4-four-three/c4-four-three-one/c4-four-three-one.component';
import { C4FourThreeTwoOneComponent } from 'src/app/view/naac/ssr/creteria-four/c4-four-three/c4-four-three-two/c4-four-three-two-one/c4-four-three-two-one.component';
import { C4FourThreeTwoComponent } from 'src/app/view/naac/ssr/creteria-four/c4-four-three/c4-four-three-two/c4-four-three-two.component';
import { C4FourThreeComponent } from 'src/app/view/naac/ssr/creteria-four/c4-four-three/c4-four-three.component';
import { C4FourTwoOneComponent } from 'src/app/view/naac/ssr/creteria-four/c4-four-two/c4-four-two-one/c4-four-two-one.component';
import { C4FourTwoComponent } from 'src/app/view/naac/ssr/creteria-four/c4-four-two/c4-four-two.component';
import { C5FiveFourOneComponent } from 'src/app/view/naac/ssr/creteria-five/c5-five-four/c5-five-four-one/c5-five-four-one.component';
import { C5FiveFourComponent } from 'src/app/view/naac/ssr/creteria-five/c5-five-four/c5-five-four.component';
import { C5FiveOneFourComponent } from 'src/app/view/naac/ssr/creteria-five/c5-five-one/c5-five-one-four/c5-five-one-four.component';
import { C5FiveOneOneOneComponent } from 'src/app/view/naac/ssr/creteria-five/c5-five-one/c5-five-one-one/c5-five-one-one-one/c5-five-one-one-one.component';
import { C5FiveOneOneComponent } from 'src/app/view/naac/ssr/creteria-five/c5-five-one/c5-five-one-one/c5-five-one-one.component';
import { C5FiveOneThreeOneComponent } from 'src/app/view/naac/ssr/creteria-five/c5-five-one/c5-five-one-three/c5-five-one-three-one/c5-five-one-three-one.component';
import { C5FiveOneThreeComponent } from 'src/app/view/naac/ssr/creteria-five/c5-five-one/c5-five-one-three/c5-five-one-three.component';
import { C5FiveOneTwoComponent } from 'src/app/view/naac/ssr/creteria-five/c5-five-one/c5-five-one-two/c5-five-one-two.component';
import { C5FiveOneComponent } from 'src/app/view/naac/ssr/creteria-five/c5-five-one/c5-five-one.component';
import { C5FiveThreeOneOneComponent } from 'src/app/view/naac/ssr/creteria-five/c5-five-three/c5-five-three-one/c5-five-three-one-one/c5-five-three-one-one.component';
import { C5FiveThreeOneComponent } from 'src/app/view/naac/ssr/creteria-five/c5-five-three/c5-five-three-one/c5-five-three-one.component';
import { C5FiveThreeTwoOneComponent } from 'src/app/view/naac/ssr/creteria-five/c5-five-three/c5-five-three-two/c5-five-three-two-one/c5-five-three-two-one.component';
import { C5FiveThreeTwoComponent } from 'src/app/view/naac/ssr/creteria-five/c5-five-three/c5-five-three-two/c5-five-three-two.component';
import { C5FiveThreeComponent } from 'src/app/view/naac/ssr/creteria-five/c5-five-three/c5-five-three.component';
import { C5FiveTwoOneOneComponent } from 'src/app/view/naac/ssr/creteria-five/c5-five-two/c5-five-two-one/c5-five-two-one-one/c5-five-two-one-one.component';
import { C5FiveTwoOneTwoComponent } from 'src/app/view/naac/ssr/creteria-five/c5-five-two/c5-five-two-one/c5-five-two-one-two/c5-five-two-one-two.component';
import { C5FiveTwoOneComponent } from 'src/app/view/naac/ssr/creteria-five/c5-five-two/c5-five-two-one/c5-five-two-one.component';
import { C5FiveTwoTwoOneComponent } from 'src/app/view/naac/ssr/creteria-five/c5-five-two/c5-five-two-two/c5-five-two-two-one/c5-five-two-two-one.component';
import { C5FiveTwoTwoComponent } from 'src/app/view/naac/ssr/creteria-five/c5-five-two/c5-five-two-two/c5-five-two-two.component';
import { C5FiveTwoComponent } from 'src/app/view/naac/ssr/creteria-five/c5-five-two/c5-five-two.component';
import { C6SixFiveOneComponent } from 'src/app/view/naac/ssr/creteria-six/c6-six-five/c6-six-five-one/c6-six-five-one.component';
import { C6SixFiveTwoComponent } from 'src/app/view/naac/ssr/creteria-six/c6-six-five/c6-six-five-two/c6-six-five-two.component';
import { C6SixFiveComponent } from 'src/app/view/naac/ssr/creteria-six/c6-six-five/c6-six-five.component';
import { C6SixFourOneComponent } from 'src/app/view/naac/ssr/creteria-six/c6-six-four/c6-six-four-one/c6-six-four-one.component';
import { C6SixFourComponent } from 'src/app/view/naac/ssr/creteria-six/c6-six-four/c6-six-four.component';
import { C6SixOneOneComponent } from 'src/app/view/naac/ssr/creteria-six/c6-six-one/c6-six-one-one/c6-six-one-one.component';
import { C6SixOneComponent } from 'src/app/view/naac/ssr/creteria-six/c6-six-one/c6-six-one.component';
import { C6SixThreeOneComponent } from 'src/app/view/naac/ssr/creteria-six/c6-six-three/c6-six-three-one/c6-six-three-one.component';
import { C6SixThreeThreeOneComponent } from 'src/app/view/naac/ssr/creteria-six/c6-six-three/c6-six-three-three/c6-six-three-three-one/c6-six-three-three-one.component';
import { C6SixThreeThreeTwoComponent } from 'src/app/view/naac/ssr/creteria-six/c6-six-three/c6-six-three-three/c6-six-three-three-two/c6-six-three-three-two.component';
import { C6SixThreeThreeComponent } from 'src/app/view/naac/ssr/creteria-six/c6-six-three/c6-six-three-three/c6-six-three-three.component';
import { C6SixThreeTwoOneComponent } from 'src/app/view/naac/ssr/creteria-six/c6-six-three/c6-six-three-two/c6-six-three-two-one/c6-six-three-two-one.component';
import { C6SixThreeTwoComponent } from 'src/app/view/naac/ssr/creteria-six/c6-six-three/c6-six-three-two/c6-six-three-two.component';
import { C6SixThreeComponent } from 'src/app/view/naac/ssr/creteria-six/c6-six-three/c6-six-three.component';
import { C6SixTwoOneComponent } from 'src/app/view/naac/ssr/creteria-six/c6-six-two/c6-six-two-one/c6-six-two-one.component';
import { C6SixTwoTwoComponent } from 'src/app/view/naac/ssr/creteria-six/c6-six-two/c6-six-two-two/c6-six-two-two.component';
import { C6SixTwoComponent } from 'src/app/view/naac/ssr/creteria-six/c6-six-two/c6-six-two.component';
import { C7SevenOneFourComponent } from 'src/app/view/naac/ssr/creteria-seven/c7-seven-one/c7-seven-one-four/c7-seven-one-four.component';
import { C7SevenOneOneComponent } from 'src/app/view/naac/ssr/creteria-seven/c7-seven-one/c7-seven-one-one/c7-seven-one-one.component';
import { C7SevenOneThreeComponent } from 'src/app/view/naac/ssr/creteria-seven/c7-seven-one/c7-seven-one-three/c7-seven-one-three.component';
import { C7SevenOneTwoComponent } from 'src/app/view/naac/ssr/creteria-seven/c7-seven-one/c7-seven-one-two/c7-seven-one-two.component';
import { C7SevenOneComponent } from 'src/app/view/naac/ssr/creteria-seven/c7-seven-one/c7-seven-one.component';
import { C7SevenThreeOneComponent } from 'src/app/view/naac/ssr/creteria-seven/c7-seven-three/c7-seven-three-one/c7-seven-three-one.component';
import { C7SevenThreeComponent } from 'src/app/view/naac/ssr/creteria-seven/c7-seven-three/c7-seven-three.component';
import { C7SevenTwoOneComponent } from 'src/app/view/naac/ssr/creteria-seven/c7-seven-two/c7-seven-two-one/c7-seven-two-one.component';
import { C7SevenTwoComponent } from 'src/app/view/naac/ssr/creteria-seven/c7-seven-two/c7-seven-two.component';
import { C1OneFourOneOneComponent } from 'src/app/view/naac/ssr/creteria-one/one-four/c1-one-four-one/c1-one-four-one-one/c1-one-four-one-one.component';
import { C1OneFourOneThreeComponent } from 'src/app/view/naac/ssr/creteria-one/one-four/c1-one-four-one/c1-one-four-one-three/c1-one-four-one-three.component';
import { C1OneFourOneTwoComponent } from 'src/app/view/naac/ssr/creteria-one/one-four/c1-one-four-one/c1-one-four-one-two/c1-one-four-one-two.component';
import { C1OneFourOneComponent } from 'src/app/view/naac/ssr/creteria-one/one-four/c1-one-four-one/c1-one-four-one.component';
import { C1OneOneOneEightComponent } from 'src/app/view/naac/ssr/creteria-one/c-one-one/c1-one-one-one/c1-one-one-one-eight/c1-one-one-one-eight.component';
import { C1OneOneOneFiveComponent } from 'src/app/view/naac/ssr/creteria-one/c-one-one/c1-one-one-one/c1-one-one-one-five/c1-one-one-one-five.component';
import { C1OneOneOneFourComponent } from 'src/app/view/naac/ssr/creteria-one/c-one-one/c1-one-one-one/c1-one-one-one-four/c1-one-one-one-four.component';
import { C1OneOneOneNineComponent } from 'src/app/view/naac/ssr/creteria-one/c-one-one/c1-one-one-one/c1-one-one-one-nine/c1-one-one-one-nine.component';
import { C1OneOneOneOneComponent } from 'src/app/view/naac/ssr/creteria-one/c-one-one/c1-one-one-one/c1-one-one-one-one/c1-one-one-one-one.component';
import { C1OneOneOneSevenComponent } from 'src/app/view/naac/ssr/creteria-one/c-one-one/c1-one-one-one/c1-one-one-one-seven/c1-one-one-one-seven.component';
import { C1OneOneOneSixComponent } from 'src/app/view/naac/ssr/creteria-one/c-one-one/c1-one-one-one/c1-one-one-one-six/c1-one-one-one-six.component';
import { C1OneOneOneThreeComponent } from 'src/app/view/naac/ssr/creteria-one/c-one-one/c1-one-one-one/c1-one-one-one-three/c1-one-one-one-three.component';
import { C1OneOneOneTwoComponent } from 'src/app/view/naac/ssr/creteria-one/c-one-one/c1-one-one-one/c1-one-one-one-two/c1-one-one-one-two.component';
import { C1OneOneOneComponent } from 'src/app/view/naac/ssr/creteria-one/c-one-one/c1-one-one-one/c1-one-one-one.component';
import { C1OneThreeOneComponent } from 'src/app/view/naac/ssr/creteria-one/c-one-three/c1-one-three-one/c1-one-three-one.component';
import { C1OneThreeTwoComponent } from 'src/app/view/naac/ssr/creteria-one/c-one-three/c1-one-three-two/c1-one-three-two.component';
import { OneeightOnenineComponent } from 'src/app/view/naac/ssr/creteria-three/c3-three-four/c3-three-four-three/oneeight-onenine/oneeight-onenine.component';
import { OnenineTwozeroComponent } from 'src/app/view/naac/ssr/creteria-three/c3-three-four/c3-three-four-three/onenine-twozero/onenine-twozero.component';
import { TwooneTwotwoComponent } from 'src/app/view/naac/ssr/creteria-three/c3-three-four/c3-three-four-three/twoone-twotwo/twoone-twotwo.component';
import { TwozeroTwooneComponent } from 'src/app/view/naac/ssr/creteria-three/c3-three-four/c3-three-four-three/twozero-twoone/twozero-twoone.component';
import { EighteenNineteenComponent } from 'src/app/view/naac/ssr/creteria-three/c3-three-four/c3-three-four-three/c3-three-four-three-one/eighteen-nineteen/eighteen-nineteen.component';
import { NineteennTwentyComponent } from 'src/app/view/naac/ssr/creteria-three/c3-three-four/c3-three-four-three/c3-three-four-three-one/nineteenn-twenty/nineteenn-twenty.component';
import { TwentyTwentyoneComponent } from 'src/app/view/naac/ssr/creteria-three/c3-three-four/c3-three-four-three/c3-three-four-three-one/twenty-twentyone/twenty-twentyone.component';
import { TwentyoneTwentytwoComponent } from 'src/app/view/naac/ssr/creteria-three/c3-three-four/c3-three-four-three/c3-three-four-three-one/twentyone-twentytwo/twentyone-twentytwo.component';
import { COneTwoOneOneComponent } from 'src/app/view/naac/ssr/creteria-one/c-one-two/c-one-two-one/c-one-two-one-one/c-one-two-one-one.component';
import { COneTwoOneTwoComponent } from 'src/app/view/naac/ssr/creteria-one/c-one-two/c-one-two-one/c-one-two-one-two/c-one-two-one-two.component';
import { COneTwoTwoComponent } from 'src/app/view/naac/ssr/creteria-one/c-one-two/c-one-two-two/c-one-two-two.component';
import { COneTwoOneComponent } from 'src/app/view/naac/ssr/creteria-one/c-one-two/c-one-two-one/c-one-two-one.component';
import { COneTwoTwoOneComponent } from 'src/app/view/naac/ssr/creteria-one/c-one-two/c-one-two-two/c-one-two-two-one/c-one-two-two-one.component';
import { TwoThreeOneComponent } from 'src/app/view/naac/ssr/creteria-two/two-three/two-three-one/two-three-one.component';
import { IctComponent } from 'src/app/view/naac/ssr/creteria-two/two-three/two-three-one/ict/ict.component';
import { PptComponent } from 'src/app/view/naac/ssr/creteria-two/two-three/two-three-one/ppt/ppt.component';
import { AmandeepComponent } from 'src/app/view/naac/ssr/creteria-two/two-three/two-three-one/ppt/amandeep/amandeep.component';
import { AmarjeetComponent } from 'src/app/view/naac/ssr/creteria-two/two-three/two-three-one/ppt/amarjeet/amarjeet.component';
import { AmritaComponent } from 'src/app/view/naac/ssr/creteria-two/two-three/two-three-one/ppt/amrita/amrita.component';
import { AnuradhaComponent } from 'src/app/view/naac/ssr/creteria-two/two-three/two-three-one/ppt/anuradha/anuradha.component';
import { AnuragComponent } from 'src/app/view/naac/ssr/creteria-two/two-three/two-three-one/ppt/anurag/anurag.component';
import { ApMishraComponent } from 'src/app/view/naac/ssr/creteria-two/two-three/two-three-one/ppt/ap-mishra/ap-mishra.component';
import { AshokComponent } from 'src/app/view/naac/ssr/creteria-two/two-three/two-three-one/ppt/ashok/ashok.component';
import { BaljeetComponent } from 'src/app/view/naac/ssr/creteria-two/two-three/two-three-one/ppt/baljeet/baljeet.component';
import { BhawnaComponent } from 'src/app/view/naac/ssr/creteria-two/two-three/two-three-one/ppt/bhawna/bhawna.component';
import { BodhrajComponent } from 'src/app/view/naac/ssr/creteria-two/two-three/two-three-one/ppt/bodhraj/bodhraj.component';
import { DheerajComponent } from 'src/app/view/naac/ssr/creteria-two/two-three/two-three-one/ppt/dheeraj/dheeraj.component';
import { EtenderComponent } from 'src/app/view/naac/ssr/creteria-two/two-three/two-three-one/ppt/etender/etender.component';
import { GurvinderComponent } from 'src/app/view/naac/ssr/creteria-two/two-three/two-three-one/ppt/gurvinder/gurvinder.component';
import { HariKiranComponent } from 'src/app/view/naac/ssr/creteria-two/two-three/two-three-one/ppt/hari-kiran/hari-kiran.component';
import { IqbalComponent } from 'src/app/view/naac/ssr/creteria-two/two-three/two-three-one/ppt/iqbal/iqbal.component';
import { JagatComponent } from 'src/app/view/naac/ssr/creteria-two/two-three/two-three-one/ppt/jagat/jagat.component';
import { JasmeenComponent } from 'src/app/view/naac/ssr/creteria-two/two-three/two-three-one/ppt/jasmeen/jasmeen.component';
import { JaspreetComponent } from 'src/app/view/naac/ssr/creteria-two/two-three/two-three-one/ppt/jaspreet/jaspreet.component';
import { JaswinderComponent } from 'src/app/view/naac/ssr/creteria-two/two-three/two-three-one/ppt/jaswinder/jaswinder.component';
import { JoshpreetComponent } from 'src/app/view/naac/ssr/creteria-two/two-three/two-three-one/ppt/joshpreet/joshpreet.component';
import { KamalpreetComponent } from 'src/app/view/naac/ssr/creteria-two/two-three/two-three-one/ppt/kamalpreet/kamalpreet.component';
import { KanikaComponent } from 'src/app/view/naac/ssr/creteria-two/two-three/two-three-one/ppt/kanika/kanika.component';
import { KavitaComponent } from 'src/app/view/naac/ssr/creteria-two/two-three/two-three-one/ppt/kavita/kavita.component';
import { KiranpalComponent } from 'src/app/view/naac/ssr/creteria-two/two-three/two-three-one/ppt/kiranpal/kiranpal.component';
import { MandeepComponent } from 'src/app/view/naac/ssr/creteria-two/two-three/two-three-one/ppt/mandeep/mandeep.component';
import { ManpuneetComponent } from 'src/app/view/naac/ssr/creteria-two/two-three/two-three-one/ppt/manpuneet/manpuneet.component';
import { MeenuComponent } from 'src/app/view/naac/ssr/creteria-two/two-three/two-three-one/ppt/meenu/meenu.component';
import { MpAggarwalComponent } from 'src/app/view/naac/ssr/creteria-two/two-three/two-three-one/ppt/mp-aggarwal/mp-aggarwal.component';
import { NeelamComponent } from 'src/app/view/naac/ssr/creteria-two/two-three/two-three-one/ppt/neelam/neelam.component';
import { NeenaComponent } from 'src/app/view/naac/ssr/creteria-two/two-three/two-three-one/ppt/neena/neena.component';
import { NidhiComponent } from 'src/app/view/naac/ssr/creteria-two/two-three/two-three-one/ppt/nidhi/nidhi.component';
import { NpSinghComponent } from 'src/app/view/naac/ssr/creteria-two/two-three/two-three-one/ppt/np-singh/np-singh.component';
import { PardeepComponent } from 'src/app/view/naac/ssr/creteria-two/two-three/two-three-one/ppt/pardeep/pardeep.component';
import { ParveenComponent } from 'src/app/view/naac/ssr/creteria-two/two-three/two-three-one/ppt/parveen/parveen.component';
import { PoojaComponent } from 'src/app/view/naac/ssr/creteria-two/two-three/two-three-one/ppt/pooja/pooja.component';
import { PratibhaComponent } from 'src/app/view/naac/ssr/creteria-two/two-three/two-three-one/ppt/pratibha/pratibha.component';
import { RajuComponent } from 'src/app/view/naac/ssr/creteria-two/two-three/two-three-one/ppt/raju/raju.component';
import { RanjitComponent } from 'src/app/view/naac/ssr/creteria-two/two-three/two-three-one/ppt/ranjit/ranjit.component';
import { RavinderComponent } from 'src/app/view/naac/ssr/creteria-two/two-three/two-three-one/ppt/ravinder/ravinder.component';
import { RavneetComponent } from 'src/app/view/naac/ssr/creteria-two/two-three/two-three-one/ppt/ravneet/ravneet.component';
import { RenuComponent } from 'src/app/view/naac/ssr/creteria-two/two-three/two-three-one/ppt/renu/renu.component';
import { RsVohraComponent } from 'src/app/view/naac/ssr/creteria-two/two-three/two-three-one/ppt/rs-vohra/rs-vohra.component';
import { SahibComponent } from 'src/app/view/naac/ssr/creteria-two/two-three/two-three-one/ppt/sahib/sahib.component';
import { SangeetaComponent } from 'src/app/view/naac/ssr/creteria-two/two-three/two-three-one/ppt/sangeeta/sangeeta.component';
import { SantoshComponent } from 'src/app/view/naac/ssr/creteria-two/two-three/two-three-one/ppt/santosh/santosh.component';
import { SeemaComponent } from 'src/app/view/naac/ssr/creteria-two/two-three/two-three-one/ppt/seema/seema.component';
import { ShaliniComponent } from 'src/app/view/naac/ssr/creteria-two/two-three/two-three-one/ppt/shalini/shalini.component';
import { ShawetaComponent } from 'src/app/view/naac/ssr/creteria-two/two-three/two-three-one/ppt/shaweta/shaweta.component';
import { ShilpiComponent } from 'src/app/view/naac/ssr/creteria-two/two-three/two-three-one/ppt/shilpi/shilpi.component';
import { ShipraComponent } from 'src/app/view/naac/ssr/creteria-two/two-three/two-three-one/ppt/shipra/shipra.component';
import { SmritiComponent } from 'src/app/view/naac/ssr/creteria-two/two-three/two-three-one/ppt/smriti/smriti.component';
import { TilakComponent } from 'src/app/view/naac/ssr/creteria-two/two-three/two-three-one/ppt/tilak/tilak.component';
import { VarshaComponent } from 'src/app/view/naac/ssr/creteria-two/two-three/two-three-one/ppt/varsha/varsha.component';
import { VineyComponent } from 'src/app/view/naac/ssr/creteria-two/two-three/two-three-one/ppt/viney/viney.component';
import { CriteriaTwoComponent } from 'src/app/view/naac/dvv/criteria-two/criteria-two.component';
import { CtTwoFourOneComponent } from 'src/app/view/naac/dvv/criteria-two/ct-two-four-one/ct-two-four-one.component';
import { CtTwoFourTwoComponent } from 'src/app/view/naac/dvv/criteria-two/ct-two-four-two/ct-two-four-two.component';
import { CtTwoSixThreeComponent } from 'src/app/view/naac/dvv/criteria-two/ct-two-six-three/ct-two-six-three.component';
import { CtTwoTwoOneComponent } from 'src/app/view/naac/dvv/criteria-two/ct-two-two-one/ct-two-two-one.component';
import { CriteriaFiveComponent } from 'src/app/view/naac/dvv/criteria-five/criteria-five.component';
import { CtFiveOneOneComponent } from 'src/app/view/naac/dvv/criteria-five/ct-five-one-one/ct-five-one-one.component';
import { CtFiveTwoOneComponent } from 'src/app/view/naac/dvv/criteria-five/ct-five-two-one/ct-five-two-one.component';
import { CriteriaThreeComponent } from 'src/app/view/naac/dvv/criteria-three/criteria-three.component';
import { CtThreeFourThreeOneComponent } from 'src/app/view/naac/dvv/criteria-three/ct-three-four-three-one/ct-three-four-three-one.component';
import { CtThreeThreeOneOneComponent } from 'src/app/view/naac/dvv/criteria-three/ct-three-three-one/ct-three-three-one-one/ct-three-three-one-one.component';
import { CtThreeThreeOneComponent } from 'src/app/view/naac/dvv/criteria-three/ct-three-three-one/ct-three-three-one.component';
import { CtThreeThreeTwoOneComponent } from 'src/app/view/naac/dvv/criteria-three/ct-three-three-two/ct-three-three-two-one/ct-three-three-two-one.component';
import { CtThreeThreeTwoComponent } from 'src/app/view/naac/dvv/criteria-three/ct-three-three-two/ct-three-three-two.component';
import { ActivityPhotosComponent } from 'src/app/view/naac/dvv/criteria-three/ct-three-four-three-one/activity-photos/activity-photos.component';
import { ActivityReportComponent } from 'src/app/view/naac/dvv/criteria-three/ct-three-four-three-one/activity-report/activity-report.component';
import { C3EighteenNineteenComponent } from 'src/app/view/naac/dvv/criteria-three/ct-three-four-three-one/activity-report/c3-eighteen-nineteen/c3-eighteen-nineteen.component';
import { C3NineteenTwentyComponent } from 'src/app/view/naac/dvv/criteria-three/ct-three-four-three-one/activity-report/c3-nineteen-twenty/c3-nineteen-twenty.component';
import { C3TwentyTwentyoneComponent } from 'src/app/view/naac/dvv/criteria-three/ct-three-four-three-one/activity-report/c3-twenty-twentyone/c3-twenty-twentyone.component';
import { C3TwentyoneTwentytwoComponent } from 'src/app/view/naac/dvv/criteria-three/ct-three-four-three-one/activity-report/c3-twentyone-twentytwo/c3-twentyone-twentytwo.component';
import { C34EighteenNineteenComponent } from 'src/app/view/naac/dvv/criteria-three/ct-three-four-three-one/activity-photos/c34-eighteen-nineteen/c34-eighteen-nineteen.component';
import { C34NineteenTwentyComponent } from 'src/app/view/naac/dvv/criteria-three/ct-three-four-three-one/activity-photos/c34-nineteen-twenty/c34-nineteen-twenty.component';
import { C34TwentyTwentyoneComponent } from 'src/app/view/naac/dvv/criteria-three/ct-three-four-three-one/activity-photos/c34-twenty-twentyone/c34-twenty-twentyone.component';
import { C34TwentyoneTwentytwoComponent } from 'src/app/view/naac/dvv/criteria-three/ct-three-four-three-one/activity-photos/c34-twentyone-twentytwo/c34-twentyone-twentytwo.component';
import { C33TwentyComponent } from 'src/app/view/naac/dvv/criteria-three/ct-three-three-two/ct-three-three-two-one/c33-twenty/c33-twenty.component';
import { C33NineteenComponent } from 'src/app/view/naac/dvv/criteria-three/ct-three-three-two/ct-three-three-two-one/c33-nineteen/c33-nineteen.component';
import { C33SeventeenComponent } from 'src/app/view/naac/dvv/criteria-three/ct-three-three-two/ct-three-three-two-one/c33-seventeen/c33-seventeen.component';
import { C33TwentyoneComponent } from 'src/app/view/naac/dvv/criteria-three/ct-three-three-two/ct-three-three-two-one/c33-twentyone/c33-twentyone.component';
import { C331EighteenComponent } from 'src/app/view/naac/dvv/criteria-three/ct-three-three-one/ct-three-three-one-one/c331-eighteen/c331-eighteen.component';
import { C331NineteenComponent } from 'src/app/view/naac/dvv/criteria-three/ct-three-three-one/ct-three-three-one-one/c331-nineteen/c331-nineteen.component';
import { C331SeventeenComponent } from 'src/app/view/naac/dvv/criteria-three/ct-three-three-one/ct-three-three-one-one/c331-seventeen/c331-seventeen.component';
import { C331TwentyComponent } from 'src/app/view/naac/dvv/criteria-three/ct-three-three-one/ct-three-three-one-one/c331-twenty/c331-twenty.component';
import { C331TwentyoneComponent } from 'src/app/view/naac/dvv/criteria-three/ct-three-three-one/ct-three-three-one-one/c331-twentyone/c331-twentyone.component';
import { CtThreeTwoTwoComponent } from 'src/app/view/naac/dvv/criteria-three/ct-three-two-two/ct-three-two-two.component';
import { C32NineteenComponent } from 'src/app/view/naac/dvv/criteria-three/ct-three-two-two/c32-nineteen/c32-nineteen.component';
import { C32TwentyComponent } from 'src/app/view/naac/dvv/criteria-three/ct-three-two-two/c32-twenty/c32-twenty.component';
import { C32TwentyoneComponent } from 'src/app/view/naac/dvv/criteria-three/ct-three-two-two/c32-twentyone/c32-twentyone.component';
import { CriteriaOneComponent } from 'src/app/view/naac/dvv/criteria-one/criteria-one.component';
import { CtOneFourOneComponent } from 'src/app/view/naac/dvv/criteria-one/ct-one-four-one/ct-one-four-one.component';
import { CtOneThreeTwoComponent } from 'src/app/view/naac/dvv/criteria-one/ct-one-three-two/ct-one-three-two.component';
import { CtOneTwoOneComponent } from 'src/app/view/naac/dvv/criteria-one/ct-one-two-one/ct-one-two-one.component';
import { CtOneTwoTwoComponent } from 'src/app/view/naac/dvv/criteria-one/ct-one-two-two/ct-one-two-two.component';
import { CtOneThreeTwoThreeComponent } from 'src/app/view/naac/dvv/criteria-one/ct-one-three-two/ct-one-three-two-three/ct-one-three-two-three.component';
import { CtOneTwoTwoFourComponent } from 'src/app/view/naac/dvv/criteria-one/ct-one-two-two/ct-one-two-two-four/ct-one-two-two-four.component';
import { CtOneTwoTwoThreeComponent } from 'src/app/view/naac/dvv/criteria-one/ct-one-two-two/ct-one-two-two-three/ct-one-two-two-three.component';
import { CtOneTwoTwoTwoComponent } from 'src/app/view/naac/dvv/criteria-one/ct-one-two-two/ct-one-two-two-two/ct-one-two-two-two.component';
import { C1224EighteenComponent } from 'src/app/view/naac/dvv/criteria-one/ct-one-two-two/ct-one-two-two-four/c1224-eighteen/c1224-eighteen.component';
import { C1224NineteenComponent } from 'src/app/view/naac/dvv/criteria-one/ct-one-two-two/ct-one-two-two-four/c1224-nineteen/c1224-nineteen.component';
import { C1224SeventeenComponent } from 'src/app/view/naac/dvv/criteria-one/ct-one-two-two/ct-one-two-two-four/c1224-seventeen/c1224-seventeen.component';
import { C1224TwentyComponent } from 'src/app/view/naac/dvv/criteria-one/ct-one-two-two/ct-one-two-two-four/c1224-twenty/c1224-twenty.component';
import { C1224TwentyoneComponent } from 'src/app/view/naac/dvv/criteria-one/ct-one-two-two/ct-one-two-two-four/c1224-twentyone/c1224-twentyone.component';
import { CtOneTwoOneFourComponent } from 'src/app/view/naac/dvv/criteria-one/ct-one-two-one/ct-one-two-one-four/ct-one-two-one-four.component';
import { C1214TwentyoneComponent } from 'src/app/view/naac/dvv/criteria-one/ct-one-two-one/ct-one-two-one-four/c1214-twentyone/c1214-twentyone.component';
import { C1214NineteenComponent } from 'src/app/view/naac/dvv/criteria-one/ct-one-two-one/ct-one-two-one-four/c1214-nineteen/c1214-nineteen.component';
import { C1214EighteenComponent } from 'src/app/view/naac/dvv/criteria-one/ct-one-two-one/ct-one-two-one-four/c1214-eighteen/c1214-eighteen.component';
import { C1214TwentyComponent } from 'src/app/view/naac/dvv/criteria-one/ct-one-two-one/ct-one-two-one-four/c1214-twenty/c1214-twenty.component';
import { C1214SeventeenComponent } from 'src/app/view/naac/dvv/criteria-one/ct-one-two-one/ct-one-two-one-four/c1214-seventeen/c1214-seventeen.component';
import { CtTwoOneOneComponent } from 'src/app/view/naac/dvv/criteria-two/ct-two-one-one/ct-two-one-one.component';
import { CriteriaSixComponent } from 'src/app/view/naac/dvv/criteria-six/criteria-six.component';
import { CtSixFiveTwoComponent } from 'src/app/view/naac/dvv/criteria-six/ct-six-five-two/ct-six-five-two.component';
import { CtSixThreeThreeComponent } from 'src/app/view/naac/dvv/criteria-six/ct-six-three-three/ct-six-three-three.component';
import { CtSixThreeTwoComponent } from 'src/app/view/naac/dvv/criteria-six/ct-six-three-two/ct-six-three-two.component';
import { CtSixTwoTwoComponent } from 'src/app/view/naac/dvv/criteria-six/ct-six-two-two/ct-six-two-two.component';
import { CtFiveOneTwoComponent } from 'src/app/view/naac/dvv/criteria-five/ct-five-one-two/ct-five-one-two.component';
import { CtFiveOneThreeComponent } from 'src/app/view/naac/dvv/criteria-five/ct-five-one-three/ct-five-one-three.component';
import { CtFiveTwoTwoComponent } from 'src/app/view/naac/dvv/criteria-five/ct-five-two-two/ct-five-two-two.component';
import { CriteriaSevenComponent } from 'src/app/view/naac/dvv/criteria-seven/criteria-seven.component';
import { CtSevenOneThreeComponent } from 'src/app/view/naac/dvv/criteria-seven/ct-seven-one-three/ct-seven-one-three.component';
import { CtSevenOneTwoComponent } from 'src/app/view/naac/dvv/criteria-seven/ct-seven-one-two/ct-seven-one-two.component';
import { CtFiveThreeOneComponent } from 'src/app/view/naac/dvv/criteria-five/ct-five-three-one/ct-five-three-one.component';
import { CtFiveThreeTwoComponent } from 'src/app/view/naac/dvv/criteria-five/ct-five-three-two/ct-five-three-two.component';
import { CtFiveOneFourComponent } from 'src/app/view/naac/dvv/criteria-five/ct-five-one-four/ct-five-one-four.component';
import { CriteriaFourComponent } from 'src/app/view/naac/dvv/criteria-four/criteria-four.component';
import { CtFourOneOneComponent } from 'src/app/view/naac/dvv/criteria-four/ct-four-one-one/ct-four-one-one.component';
import { CtTwoOneTwoComponent } from 'src/app/view/naac/dvv/criteria-two/ct-two-one-two/ct-two-one-two.component';
import { CtTwoOneComponent } from 'src/app/view/naac/dvv/extended-profile/ct-two-one/ct-two-one.component';
import { ExtendedProfileComponent } from 'src/app/view/naac/dvv/extended-profile/extended-profile.component';
import { SelfStudyComponent } from 'src/app/view/naac/ssr/self-study/self-study.component';
import { TeamReportComponent } from 'src/app/view/naac/team-report/team-report.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TwothreeTwofourComponent } from 'src/app/view/naac/aqar/twothree-twofour/twothree-twofour.component';
import { Aqar23ProfileComponent } from 'src/app/view/naac/aqar/twothree-twofour/aqar23-profile/aqar23-profile.component';
import { Aqar23C1Component } from 'src/app/view/naac/aqar/twothree-twofour/aqar23-c1/aqar23-c1.component';
import { Aqar23C5Component } from 'src/app/view/naac/aqar/twothree-twofour/aqar23-c5/aqar23-c5.component';
import { Aqar23C6Component } from 'src/app/view/naac/aqar/twothree-twofour/aqar23-c6/aqar23-c6.component';
import { Aqar23C7Component } from 'src/app/view/naac/aqar/twothree-twofour/aqar23-c7/aqar23-c7.component';
import { Aqar23C1OneThreeTwoComponent } from 'src/app/view/naac/aqar/twothree-twofour/aqar23-c1/aqar23-c1-one-three-two/aqar23-c1-one-three-two.component';
import { Aqar23C5FiveOneOneComponent } from 'src/app/view/naac/aqar/twothree-twofour/aqar23-c5/aqar23-c5-five-one-one/aqar23-c5-five-one-one.component';
import { Aqar23C6SixOneTwoComponent } from 'src/app/view/naac/aqar/twothree-twofour/aqar23-c6/aqar23-c6-six-one-two/aqar23-c6-six-one-two.component';
import { Aqar23C7SevenOneNineComponent } from 'src/app/view/naac/aqar/twothree-twofour/aqar23-c7/aqar23-c7-seven-one-nine/aqar23-c7-seven-one-nine.component';
import { Aqar23C7SevenThreeOneComponent } from 'src/app/view/naac/aqar/twothree-twofour/aqar23-c7/aqar23-c7-seven-three-one/aqar23-c7-seven-three-one.component';
import { Aqar23C7SevenOneOneComponent } from 'src/app/view/naac/aqar/twothree-twofour/aqar23-c7/aqar23-c7-seven-one-one/aqar23-c7-seven-one-one.component';
import { Aqar23C7SevenTwoOneComponent } from 'src/app/view/naac/aqar/twothree-twofour/aqar23-c7/aqar23-c7-seven-two-one/aqar23-c7-seven-two-one.component';
import { Aqar23C3ThreeTwoTwoComponent } from 'src/app/view/naac/aqar/twothree-twofour/aqar23-c3/aqar23-c3-three-two-two/aqar23-c3-three-two-two.component';
import { Aqar23C3Component } from 'src/app/view/naac/aqar/twothree-twofour/aqar23-c3/aqar23-c3.component';
import { AqOneeightOnenineComponent } from 'src/app/view/naac/aqar/aq-oneeight-onenine/aq-oneeight-onenine.component';
import { AqOnefiveOnesixComponent } from 'src/app/view/naac/aqar/aq-onefive-onesix/aq-onefive-onesix.component';
import { AqOnenineTwozeroComponent } from 'src/app/view/naac/aqar/aq-onenine-twozero/aq-onenine-twozero.component';
import { AqOnesevenOneeightComponent } from 'src/app/view/naac/aqar/aq-oneseven-oneeight/aq-oneseven-oneeight.component';
import { AqOnesixOnesevenComponent } from 'src/app/view/naac/aqar/aq-onesix-oneseven/aq-onesix-oneseven.component';
import { AqTwooneTwotwoComponent } from 'src/app/view/naac/aqar/aq-twoone-twotwo/aq-twoone-twotwo.component';
import { AqTwotwoTwothreeComponent } from 'src/app/view/naac/aqar/aq-twotwo-twothree/aq-twotwo-twothree.component';
import { AqTwozeroTwooneComponent } from 'src/app/view/naac/aqar/aq-twozero-twoone/aq-twozero-twoone.component';
import { Aqar23C1OneFourTwoComponent } from 'src/app/view/naac/aqar/twothree-twofour/aqar23-c1/aqar23-c1-one-four-two/aqar23-c1-one-four-two.component';
import { Aqar23C2TwoSevenOneComponent } from 'src/app/view/naac/aqar/twothree-twofour/aqar23-c2/aqar23-c2-two-seven-one/aqar23-c2-two-seven-one.component';
import { Aqar23C2Component } from 'src/app/view/naac/aqar/twothree-twofour/aqar23-c2/aqar23-c2.component';
import { Aqar23C3ThreeFiveOneComponent } from 'src/app/view/naac/aqar/twothree-twofour/aqar23-c3/aqar23-c3-three-five-one/aqar23-c3-three-five-one.component';
import { Aqar23C5FiveOneThreeComponent } from 'src/app/view/naac/aqar/twothree-twofour/aqar23-c5/aqar23-c5-five-one-three/aqar23-c5-five-one-three.component';



@NgModule({
  declarations: [
    NaacComponent,
    IiqaComponent,
    SsrComponent,
    DvvComponent,
    AqarComponent,
    ExecutiveComponent,
    ProfileComponent,
    ExtendedRpofileComponent,
    CreteriaOneComponent,
    CreteriaTwoComponent,
    CreteriaThreeComponent,
    CreteriaFourComponent,
    CreteriaFiveComponent,
    CreteriaSixComponent,
    CreteriaSevenComponent,
    ConclusionComponent,
    OneOneComponent,
    OneTwoComponent,
    OneThreeComponent,
    IntroductionComponent,
    SwocComponent,
    CriteriaComponent,
    TwoOneComponent,
    BasicInfoComponent,
    TwoTwoComponent,
    AcademicInfoComponent,
    COneOneComponent,
    COneTwoComponent,
    COneThreeComponent,
    OneFourComponent,
    CTwoOneComponent,
    CTwoTwoComponent,
    TwoThreeComponent,
    TwoFourComponent,
    TwoFiveComponent,
    TwoSixComponent,
    TwoSevenComponent,
    TwoOneOneComponent,
    TwoOneOneOneComponent,
    TwoOneOneTwoComponent,
    TwoOneTwoComponent,
    TwoOneTwoOneComponent,
    TwoOneTwoTwoComponent,
    TwoTwoOneComponent,
    TwoFourOneComponent,
    TwoFourTwoComponent,
    TwoFourOneOneComponent,
    TwoFourTwoOneComponent,
    TwoFiveOneComponent,
    TwoSixOneComponent,
    TwoSixTwoComponent,
    TwoSixThreeComponent,
    TwoSixThreeOneComponent,
    TwoSixThreeTwoComponent,
    TwoSevenOneComponent,
    OneComponent,
    TwoComponent,
    ThreeComponent,
    EpOneOneComponent,
    EpTwoOneComponent,
    EpTwoTwoComponent,
    EpThreeOneComponent,
    C3ThreeOneComponent,
    C3ThreeTwoComponent,
    C3ThreeThreeComponent,
    C3ThreeFourComponent,
    C3ThreeFiveComponent,
    C3ThreeOneOneComponent,
    ThreeOneOneOneComponent,
    C3ThreeTwoOneComponent,
    C3ThreeTwoTwoComponent,
    ThreeTwoTwoOneComponent,
    C3ThreeThreeOneComponent,
    C3ThreeThreeOneOneComponent,
    C3ThreeThreeTwoComponent,
    C3ThreeThreeTwoOneComponent,
    C3ThreeFourOneComponent,
    C3ThreeFourTwoComponent,
    C3ThreeFourThreeComponent,
    C3ThreeFourThreeOneComponent,
    C3ThreeFiveOneComponent,
    C4FourOneComponent,
    C4FourTwoComponent,
    C4FourThreeComponent,
    C4FourFourComponent,
    C4FourOneOneComponent,
    C4FourOneTwoComponent,
    C4FourOneTwoOneComponent,
    C4FourTwoOneComponent,
    C4FourThreeOneComponent,
    C4FourThreeTwoComponent,
    C4FourThreeTwoOneComponent,
    C4FourFourOneComponent,
    C4FourFourOneOneComponent,
    C5FiveOneComponent,
    C5FiveTwoComponent,
    C5FiveThreeComponent,
    C5FiveFourComponent,
    C5FiveOneOneComponent,
    C5FiveOneTwoComponent,
    C5FiveOneThreeComponent,
    C5FiveOneFourComponent,
    C5FiveOneOneOneComponent,
    C5FiveOneThreeOneComponent,
    C5FiveTwoOneComponent,
    C5FiveTwoTwoComponent,
    C5FiveTwoOneOneComponent,
    C5FiveTwoOneTwoComponent,
    C5FiveTwoTwoOneComponent,
    C5FiveThreeOneComponent,
    C5FiveThreeTwoComponent,
    C5FiveThreeOneOneComponent,
    C5FiveThreeTwoOneComponent,
    C5FiveFourOneComponent,
    C6SixOneComponent,
    C6SixOneOneComponent,
    C6SixTwoComponent,
    C6SixTwoOneComponent,
    C6SixTwoTwoComponent,
    C6SixThreeComponent,
    C6SixThreeOneComponent,
    C6SixThreeTwoComponent,
    C6SixThreeTwoOneComponent,
    C6SixThreeThreeComponent,
    C6SixThreeThreeOneComponent,
    C6SixThreeThreeTwoComponent,
    C6SixFourComponent,
    C6SixFourOneComponent,
    C6SixFiveComponent,
    C6SixFiveOneComponent,
    C6SixFiveTwoComponent,
    C7SevenOneComponent,
    C7SevenOneOneComponent,
    C7SevenOneTwoComponent,
    C7SevenOneThreeComponent,
    C7SevenOneFourComponent,
    C7SevenTwoComponent,
    C7SevenTwoOneComponent,
    C7SevenThreeComponent,
    C7SevenThreeOneComponent,
    C1OneFourOneComponent,
    C1OneFourOneOneComponent,
    C1OneFourOneTwoComponent,
    C1OneFourOneThreeComponent,
    C1OneOneOneComponent,
    C1OneOneOneOneComponent,
    C1OneOneOneTwoComponent,
    C1OneOneOneThreeComponent,
    C1OneOneOneFourComponent,
    C1OneOneOneFiveComponent,
    C1OneOneOneSixComponent,
    C1OneOneOneSevenComponent,
    C1OneOneOneEightComponent,
    C1OneOneOneNineComponent,
    C1OneThreeOneComponent,
    C1OneThreeTwoComponent,
    OneeightOnenineComponent,
    OnenineTwozeroComponent,
    TwozeroTwooneComponent,
    TwooneTwotwoComponent,
    EighteenNineteenComponent,
    NineteennTwentyComponent,
    TwentyTwentyoneComponent,
    TwentyoneTwentytwoComponent,
    COneTwoOneComponent,
    COneTwoTwoComponent,
    COneTwoOneOneComponent,
    COneTwoOneTwoComponent,
    COneTwoTwoOneComponent,
    TwoThreeOneComponent,
    IctComponent,
    PptComponent,
    AmandeepComponent,
    AnuradhaComponent,
    AnuragComponent,
    AshokComponent,
    BhawnaComponent,
    BodhrajComponent,
    DheerajComponent,
    AmritaComponent,
    BaljeetComponent,
    GurvinderComponent,
    IqbalComponent,
    JaswinderComponent,
    JoshpreetComponent,
    KamalpreetComponent,
    ManpuneetComponent,
    MeenuComponent,
    MpAggarwalComponent,
    RanjitComponent,
    RavinderComponent,
    SangeetaComponent,
    VarshaComponent,
    AmarjeetComponent,
    ApMishraComponent,
    HariKiranComponent,
    SahibComponent,
    EtenderComponent,
    JagatComponent,
    JasmeenComponent,
    JaspreetComponent,
    KanikaComponent,
    KavitaComponent,
    KiranpalComponent,
    MandeepComponent,
    NpSinghComponent,
    NeelamComponent,
    NeenaComponent,
    NidhiComponent,
    PardeepComponent,
    ParveenComponent,
    PoojaComponent,
    PratibhaComponent,
    RsVohraComponent,
    RajuComponent,
    RavneetComponent,
    RenuComponent,
    SantoshComponent,
    SeemaComponent,
    ShaliniComponent,
    ShawetaComponent,
    ShilpiComponent,
    ShipraComponent,
    SmritiComponent,
    TilakComponent,
    VineyComponent,
    CriteriaTwoComponent,
    CtTwoTwoOneComponent,
    CtTwoFourOneComponent,
    CtTwoFourTwoComponent,
    CtTwoSixThreeComponent,
    CriteriaFiveComponent,
    CtFiveOneOneComponent,
    CtFiveTwoOneComponent,
    CriteriaThreeComponent,
    CtThreeThreeOneComponent,
    CtThreeThreeTwoComponent,
    CtThreeFourThreeOneComponent,
    CtThreeThreeOneOneComponent,
    CtThreeThreeTwoOneComponent,
    ActivityReportComponent,
    ActivityPhotosComponent,
    C3EighteenNineteenComponent,
    C3NineteenTwentyComponent,
    C3TwentyTwentyoneComponent,
    C3TwentyoneTwentytwoComponent,
    C34NineteenTwentyComponent,
    C34EighteenNineteenComponent,
    C34TwentyTwentyoneComponent,
    C34TwentyoneTwentytwoComponent,
    C33TwentyoneComponent,
    C33TwentyComponent,
    C33NineteenComponent,
    C33SeventeenComponent,
    C331SeventeenComponent,
    C331EighteenComponent,
    C331NineteenComponent,
    C331TwentyComponent,
    C331TwentyoneComponent,
    CtThreeTwoTwoComponent,
    C32NineteenComponent,
    C32TwentyComponent,
    C32TwentyoneComponent,
    CriteriaOneComponent,
    CtOneTwoOneComponent,
    CtOneTwoTwoComponent,
    CtOneThreeTwoComponent,
    CtOneFourOneComponent,
    CtOneThreeTwoThreeComponent,
    CtOneTwoTwoTwoComponent,
    CtOneTwoTwoThreeComponent,
    CtOneTwoTwoFourComponent,
    C1224SeventeenComponent,
    C1224EighteenComponent,
    C1224NineteenComponent,
    C1224TwentyComponent,
    C1224TwentyoneComponent,
    CtOneTwoOneFourComponent,
    C1214EighteenComponent,
    C1214NineteenComponent,
    C1214SeventeenComponent,
    C1214TwentyComponent,
    C1214TwentyoneComponent,
    CtTwoOneOneComponent,
    CriteriaSixComponent,
    CtSixTwoTwoComponent,
    CtSixThreeTwoComponent,
    CtSixThreeThreeComponent,
    CtSixFiveTwoComponent,
    CtFiveOneTwoComponent,
    CtFiveOneThreeComponent,
    CtFiveTwoTwoComponent,
    CriteriaSevenComponent,
    CtSevenOneTwoComponent,
    CtSevenOneThreeComponent,
    CtFiveThreeOneComponent,
    CtFiveThreeTwoComponent,
    CtFiveOneFourComponent,
    CriteriaFourComponent,
    CtFourOneOneComponent,
    CtTwoOneTwoComponent,
    ExtendedProfileComponent,
    CtTwoOneComponent,
    SelfStudyComponent,
    TeamReportComponent,
    TwothreeTwofourComponent,
    Aqar23ProfileComponent,
    Aqar23C1Component,
    Aqar23C5Component,
    Aqar23C6Component,
    Aqar23C7Component,
    Aqar23C1OneThreeTwoComponent,
    Aqar23C5FiveOneOneComponent,
    Aqar23C6SixOneTwoComponent,
    Aqar23C7SevenOneNineComponent,
    Aqar23C7SevenThreeOneComponent,
    Aqar23C7SevenOneOneComponent,
    Aqar23C7SevenTwoOneComponent,
    Aqar23C3Component,
    Aqar23C3ThreeTwoTwoComponent,
    AqOnefiveOnesixComponent,
    AqOnesixOnesevenComponent,
    AqOnesevenOneeightComponent,
    AqOneeightOnenineComponent,
    AqOnenineTwozeroComponent,
    AqTwozeroTwooneComponent,
    AqTwooneTwotwoComponent,
    AqTwotwoTwothreeComponent,
    Aqar23C1OneFourTwoComponent,
    Aqar23C2Component,
    Aqar23C2TwoSevenOneComponent,
    Aqar23C3ThreeFiveOneComponent,
    Aqar23C5FiveOneThreeComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PdfViewerModule,
    LoaderModule,
    SharedModule,
    RouterModule.forChild([
      {
        path: '', component: NaacComponent, children: [
          { path: 'iiqa', component: IiqaComponent },
          { path: 'team-report', component: TeamReportComponent },
          {
            path: 'ssr', component: SsrComponent, children: [
              // {
              //   path: 'executive', component: ExecutiveComponent, children: [
              //     {
              //       path: '1-1', component: OneOneComponent, children: [
              //         { path: 'introduction', component: IntroductionComponent }
              //       ]
              //     },
              //     {
              //       path: '1-2', component: OneTwoComponent, children: [
              //         { path: 'swoc', component: SwocComponent }
              //       ]
              //     },
              //     {
              //       path: '1-3', component: OneThreeComponent, children: [
              //         { path: 'criteria', component: CriteriaComponent }
              //       ]
              //     },
              //   ]
              // },
              // {
              //   path: 'profile', component: ProfileComponent, children: [
              //     {
              //       path: '2-1', component: TwoOneComponent, children: [
              //         { path: 'basic-information', component: BasicInfoComponent }
              //       ]
              //     },
              //     {
              //       path: '2-2', component: TwoTwoComponent, children: [
              //         { path: 'academic-information', component: AcademicInfoComponent }
              //       ]
              //     }
              //   ]
              // },
              // {
              //   path: 'extended-profile', component: ExtendedRpofileComponent, children: [
              //     {
              //       path: '1', component: OneComponent, children: [
              //         { path: '1-1', component: EpOneOneComponent }
              //       ]
              //     },
              //     {
              //       path: '2', component: TwoComponent, children: [
              //         { path: '2-1', component: EpTwoOneComponent },
              //         { path: '2-2', component: EpTwoTwoComponent }
              //       ]
              //     },
              //     {
              //       path: '3', component: ThreeComponent, children: [
              //         { path: '3-1', component: EpThreeOneComponent }
              //       ]
              //     },
              //   ]
              // },
              { path: 'self-study-report', component: SelfStudyComponent },
              {
                path: 'criteria1', component: CreteriaOneComponent, children: [
                  {
                    path: '1-1', component: COneOneComponent, children: [
                      {
                        path: '1-1-1', component: C1OneOneOneComponent, children: [
                          { path: '1-1-1-1', component: C1OneOneOneOneComponent },
                          { path: '1-1-1-2', component: C1OneOneOneTwoComponent },
                          { path: '1-1-1-3', component: C1OneOneOneThreeComponent },
                          { path: '1-1-1-4', component: C1OneOneOneFourComponent },
                          { path: '1-1-1-5', component: C1OneOneOneFiveComponent },
                          { path: '1-1-1-6', component: C1OneOneOneSixComponent },
                          { path: '1-1-1-7', component: C1OneOneOneSevenComponent },
                          { path: '1-1-1-8', component: C1OneOneOneEightComponent },
                          { path: '1-1-1-9', component: C1OneOneOneNineComponent },
                        ]
                      }
                    ]
                  },
                  {
                    path: '1-2', component: COneTwoComponent, children: [
                      {
                        path: '1-2-1', component: COneTwoOneComponent, children: [
                          { path: '1-2-1-1', component: COneTwoOneOneComponent },
                          { path: '1-2-1-2', component: COneTwoOneTwoComponent },
                        ]
                      },
                      {
                        path: '1-2-2', component: COneTwoTwoComponent, children: [
                          { path: '1-2-2-1', component: COneTwoTwoOneComponent }
                        ]
                      }
                    ]
                  },
                  {
                    path: '1-3', component: COneThreeComponent, children: [
                      { path: '1-3-1', component: C1OneThreeOneComponent },
                      { path: '1-3-2', component: C1OneThreeTwoComponent },
                    ]
                  },
                  {
                    path: '1-4', component: OneFourComponent, children: [
                      {
                        path: '1-4-1', component: C1OneFourOneComponent, children: [
                          { path: '1-4-1-1', component: C1OneFourOneOneComponent },
                          { path: '1-4-1-2', component: C1OneFourOneTwoComponent },
                          { path: '1-4-1-3', component: C1OneFourOneThreeComponent },
                        ]
                      }
                    ]
                  },
                ]
              },
              {
                path: 'criteria2', component: CreteriaTwoComponent, children: [
                  {
                    path: '2-1', component: CTwoOneComponent, children: [
                      {
                        path: '2-1-1', component: TwoOneOneComponent, children: [
                          { path: '2-1-1-1', component: TwoOneOneOneComponent },
                          { path: '2-1-1-2', component: TwoOneOneTwoComponent },
                        ]
                      },
                      {
                        path: '2-1-2', component: TwoOneTwoComponent, children: [
                          { path: '2-1-2-1', component: TwoOneTwoOneComponent },
                          { path: '2-1-2-2', component: TwoOneTwoTwoComponent },
                        ]
                      },
                    ]
                  },
                  {
                    path: '2-2', component: CTwoTwoComponent, children: [
                      { path: '2-2-1', component: TwoTwoOneComponent },
                    ]
                  },
                  {
                    path: '2-3', component: TwoThreeComponent, children: [
                      {
                        path: '2-3-1', component: TwoThreeOneComponent, children: [
                          { path: 'ict', component: IctComponent },
                          {
                            path: 'ppt', component: PptComponent, children: [
                              { path: 'DR-AMANDEEP-KAUR', component: AmandeepComponent },
                              { path: 'PROF-ANURADHA', component: AnuradhaComponent },
                              { path: 'DR-ANURAG', component: AnuragComponent },
                              { path: 'DR-ASHOK-KHURANA', component: AshokComponent },
                              { path: 'PROF-BHAWNA-SHARMA', component: BhawnaComponent },
                              { path: 'DR-BODHRAJ ', component: BodhrajComponent },
                              { path: 'PROF-DHEERAJ', component: DheerajComponent },
                              { path: 'DR-AMRITA-PRITAM', component: AmritaComponent },
                              { path: 'DR-BALJEET-SINGH', component: BaljeetComponent },
                              { path: 'DR-GURVINDER-KAUR', component: GurvinderComponent },
                              { path: 'DR-IQBAL-SINGH', component: IqbalComponent },
                              { path: 'DR-JASWINDER-KAUR', component: JaswinderComponent },
                              { path: 'DR-JOSHPREET-SANDHU', component: JoshpreetComponent },
                              { path: 'DR-KAMALPREET-KAUR', component: KamalpreetComponent },
                              { path: 'DR-MANPUNEET-KAUR', component: ManpuneetComponent },
                              { path: 'DR-MEENU-KAPUR', component: MeenuComponent },
                              { path: 'DR-M-P-AGGARWAL', component: MpAggarwalComponent },
                              { path: 'DR-RANJIT-SINGH', component: RanjitComponent },
                              { path: 'DR-RAVINDER-KAUR', component: RavinderComponent },
                              { path: 'DR-SANGEETA ', component: SangeetaComponent },
                              { path: 'DR-VARSHA-NIGAM', component: VarshaComponent },
                              { path: 'DR-AMARJEET-SINGH', component: AmarjeetComponent },
                              { path: 'DR-A-P-MISHRA', component: ApMishraComponent },
                              { path: 'DR-HARI-KIRAN-KAUR', component: HariKiranComponent },
                              { path: 'DR-SAHIB-SINGH', component: SahibComponent },
                              { path: 'PROF-ETENDER', component: EtenderComponent },
                              { path: 'DR-JAGAT ', component: JagatComponent },
                              { path: 'PROF-JASMEEN-KAUR', component: JasmeenComponent },
                              { path: 'PROF-JASPREET-SINGH', component: JaspreetComponent },
                              { path: 'PROF-KANIKA', component: KanikaComponent },
                              { path: 'PROF-KAVITA', component: KavitaComponent },
                              { path: 'DR-KIRANPAL-SINGH-VIRK', component: KiranpalComponent },
                              { path: 'DR-MANDEEP-KAUR', component: MandeepComponent },
                              { path: 'DR-N-P-SINGH', component: NpSinghComponent },
                              { path: 'PROF-NEELAM-SINGH', component: NeelamComponent },
                              { path: 'DR-NEENA-PURI', component: NeenaComponent },
                              { path: 'DR-NIDHI', component: NidhiComponent },
                              { path: 'PROF-PARDEEP', component: PardeepComponent },
                              { path: 'DR-PARVEEN-KUMAR', component: ParveenComponent },
                              { path: 'PROF-POOJA-RANI', component: PoojaComponent },
                              { path: 'DR-PRATIBHA-SHARMA', component: PratibhaComponent },
                              { path: 'PROF-R-S-VOHRA', component: RsVohraComponent },
                              { path: 'PROF-RAJU', component: RajuComponent },
                              { path: 'PROF-RAVNEET-KAUR', component: RavneetComponent },
                              { path: 'PROF-RENU', component: RenuComponent },
                              { path: 'DR-SANTOSH-KURRA', component: SantoshComponent },
                              { path: 'PROF-SEEMA', component: SeemaComponent },
                              { path: 'PROF-SHALINI-BHARDWAJ', component: ShaliniComponent },
                              { path: 'DR-SHAWETA-SACHDEVA', component: ShawetaComponent },
                              { path: 'PROF-SHILPI', component: ShilpiComponent },
                              { path: 'PROF-SHIPRA', component: ShipraComponent },
                              { path: 'PROF-SMRITI', component: SmritiComponent },
                              { path: 'DR-TILAK-RAJ', component: TilakComponent },
                              { path: 'DR-VINEY-CHANDEL', component: VineyComponent },
                            ]
                          }
                        ]
                      }
                    ]
                  },
                  {
                    path: '2-4', component: TwoFourComponent, children: [
                      {
                        path: '2-4-1', component: TwoFourOneComponent, children: [
                          { path: '2-4-1-1', component: TwoFourOneOneComponent },
                        ]
                      },
                      {
                        path: '2-4-2', component: TwoFourTwoComponent, children: [
                          { path: '2-4-2-1', component: TwoFourTwoOneComponent },
                        ]
                      },
                    ]
                  },
                  {
                    path: '2-5', component: TwoFiveComponent, children: [
                      { path: '2-5-1', component: TwoFiveOneComponent }
                    ]
                  },
                  {
                    path: '2-6', component: TwoSixComponent, children: [
                      { path: '2-6-1', component: TwoSixOneComponent },
                      { path: '2-6-2', component: TwoSixTwoComponent },
                      {
                        path: '2-6-3', component: TwoSixThreeComponent, children: [
                          { path: '2-6-3-1', component: TwoSixThreeOneComponent },
                          { path: '2-6-3-2', component: TwoSixThreeTwoComponent },
                        ]
                      },
                    ]
                  },
                  {
                    path: '2-7', component: TwoSevenComponent, children: [
                      { path: '2-7-1', component: TwoSevenOneComponent }
                    ]
                  },
                ]
              },
              {
                path: 'criteria3', component: CreteriaThreeComponent, children: [
                  {
                    path: '3-1', component: C3ThreeOneComponent, children: [
                      {
                        path: '3-1-1', component: C3ThreeOneOneComponent, children: [
                          { path: '3-1-1-1', component: ThreeOneOneOneComponent }
                        ]
                      }
                    ]
                  },
                  {
                    path: '3-2', component: C3ThreeTwoComponent, children: [
                      { path: '3-2-1', component: C3ThreeTwoOneComponent },
                      {
                        path: '3-2-2', component: C3ThreeTwoTwoComponent, children: [
                          // { path: '3-2-2-1', component: ThreeTwoTwoOneComponent }
                        ]
                      }
                    ]
                  },
                  {
                    path: '3-3', component: C3ThreeThreeComponent, children: [
                      {
                        path: '3-3-1', component: C3ThreeThreeOneComponent, children: [
                          // { path: '3-3-1-1', component: C3ThreeThreeOneOneComponent }
                        ]
                      },
                      {
                        path: '3-3-2', component: C3ThreeThreeTwoComponent, children: [
                          { path: '3-3-2-1', component: C3ThreeThreeTwoOneComponent }
                        ]
                      }
                    ]
                  },
                  {
                    path: '3-4', component: C3ThreeFourComponent, children: [
                      { path: '3-4-1', component: C3ThreeFourOneComponent },
                      { path: '3-4-2', component: C3ThreeFourTwoComponent },
                      {
                        path: '3-4-3', component: C3ThreeFourThreeComponent, children: [
                          {
                            path: '3-4-3-1', component: C3ThreeFourThreeOneComponent, children: [
                              { path: '2021-22', component: TwentyoneTwentytwoComponent },
                              { path: '2020-21', component: TwentyTwentyoneComponent },
                              { path: '2019-20', component: NineteennTwentyComponent },
                              { path: '2018-19', component: EighteenNineteenComponent },
                            ]
                          },
                          { path: '2021-22', component: TwooneTwotwoComponent },
                          { path: '2020-21', component: TwozeroTwooneComponent },
                          { path: '2019-20', component: OnenineTwozeroComponent },
                          { path: '2018-19', component: OneeightOnenineComponent },
                        ]
                      }
                    ]
                  },
                  {
                    path: '3-5', component: C3ThreeFiveComponent, children: [
                      { path: '3-5-1', component: C3ThreeFiveOneComponent }
                    ]
                  }
                ]
              },
              {
                path: 'criteria4', component: CreteriaFourComponent, children: [
                  {
                    path: '4-1', component: C4FourOneComponent, children: [
                      { path: '4-1-1', component: C4FourOneOneComponent },
                      {
                        path: '4-1-2', component: C4FourOneTwoComponent, children: [
                          { path: '4-1-2-1', component: C4FourOneTwoOneComponent }
                        ]
                      }
                    ]
                  },
                  {
                    path: '4-2', component: C4FourTwoComponent, children: [
                      { path: '4-2-1', component: C4FourTwoOneComponent },
                    ]
                  },
                  {
                    path: '4-3', component: C4FourThreeComponent, children: [
                      { path: '4-3-1', component: C4FourThreeOneComponent },
                      {
                        path: '4-3-2', component: C4FourThreeTwoComponent, children: [
                          { path: '4-3-2-1', component: C4FourThreeTwoOneComponent }
                        ]
                      }
                    ]
                  },
                  {
                    path: '4-4', component: C4FourFourComponent, children: [
                      {
                        path: '4-4-1', component: C4FourFourOneComponent, children: [
                          { path: '4-4-1-1', component: C4FourFourOneOneComponent }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                path: 'criteria5', component: CreteriaFiveComponent, children: [
                  {
                    path: '5-1', component: C5FiveOneComponent, children: [
                      {
                        path: '5-1-1', component: C5FiveOneOneComponent, children: [
                          // { path: '5-1-1-1', component: C5FiveOneOneOneComponent }
                        ]
                      },
                      { path: '5-1-2', component: C5FiveOneTwoComponent },
                      {
                        path: '5-1-3', component: C5FiveOneThreeComponent, children: [
                          { path: '5-1-3-1', component: C5FiveOneThreeOneComponent }
                        ]
                      },
                      { path: '5-1-4', component: C5FiveOneFourComponent },
                    ]
                  },
                  {
                    path: '5-2', component: C5FiveTwoComponent, children: [
                      {
                        path: '5-2-1', component: C5FiveTwoOneComponent, children: [
                          { path: '5-2-1-1', component: C5FiveTwoOneOneComponent },
                          { path: '5-2-1-2', component: C5FiveTwoOneTwoComponent },
                        ]
                      },
                      {
                        path: '5-2-2', component: C5FiveTwoTwoComponent, children: [
                          { path: '5-2-2-1', component: C5FiveTwoTwoOneComponent }
                        ]
                      },
                    ]
                  },
                  {
                    path: '5-3', component: C5FiveThreeComponent, children: [
                      {
                        path: '5-3-1', component: C5FiveThreeOneComponent, children: [
                          { path: '5-3-1-1', component: C5FiveThreeOneOneComponent }
                        ]
                      },
                      {
                        path: '5-3-2', component: C5FiveThreeTwoComponent, children: [
                          { path: '5-3-2-1', component: C5FiveThreeTwoOneComponent }
                        ]
                      }
                    ]
                  },
                  {
                    path: '5-4', component: C5FiveFourComponent, children: [
                      { path: '5-4-1', component: C5FiveFourOneComponent }
                    ]
                  }
                ]
              },
              {
                path: 'criteria6', component: CreteriaSixComponent, children: [
                  {
                    path: '6-1', component: C6SixOneComponent, children: [
                      { path: '6-1-1', component: C6SixOneOneComponent }
                    ]
                  },
                  {
                    path: '6-2', component: C6SixTwoComponent, children: [
                      { path: '6-2-1', component: C6SixTwoOneComponent },
                      { path: '6-2-2', component: C6SixTwoTwoComponent },
                    ]
                  },
                  {
                    path: '6-3', component: C6SixThreeComponent, children: [
                      { path: '6-3-1', component: C6SixThreeOneComponent },
                      {
                        path: '6-3-2', component: C6SixThreeTwoComponent, children: [
                          { path: '6-3-2-1', component: C6SixThreeTwoOneComponent },
                        ]
                      },
                      {
                        path: '6-3-3', component: C6SixThreeThreeComponent, children: [
                          { path: '6-3-3-1', component: C6SixThreeThreeOneComponent },
                          { path: '6-3-3-2', component: C6SixThreeThreeTwoComponent },
                        ]
                      },
                    ]
                  },
                  {
                    path: '6-4', component: C6SixFourComponent, children: [
                      { path: '6-4-1', component: C6SixFourOneComponent }
                    ]
                  },
                  {
                    path: '6-5', component: C6SixFiveComponent, children: [
                      { path: '6-5-1', component: C6SixFiveOneComponent },
                      { path: '6-5-2', component: C6SixFiveTwoComponent },
                    ]
                  },
                ]
              },
              {
                path: 'criteria7', component: CreteriaSevenComponent, children: [
                  {
                    path: '7-1', component: C7SevenOneComponent, children: [
                      { path: '7-1-1', component: C7SevenOneOneComponent },
                      { path: '7-1-2', component: C7SevenOneTwoComponent },
                      { path: '7-1-3', component: C7SevenOneThreeComponent },
                      { path: '7-1-4', component: C7SevenOneFourComponent },
                    ]
                  },
                  {
                    path: '7-2', component: C7SevenTwoComponent, children: [
                      { path: '7-2-1', component: C7SevenTwoOneComponent },
                    ]
                  },
                  {
                    path: '7-3', component: C7SevenThreeComponent, children: [
                      { path: '7-3-1', component: C7SevenThreeOneComponent },
                    ]
                  },
                ]
              },
              // { path: 'conclusion', component: ConclusionComponent }
            ]
          },
          {
            path: 'dvv', component: DvvComponent, children: [
              {
                path: 'extended-profile', component: ExtendedProfileComponent, children: [
                  { path: '2-1', component: CtTwoOneComponent }
                ]
              },
              {
                path: 'criteria1', component: CriteriaOneComponent, children: [
                  {
                    path: '1-2-1', component: CtOneTwoOneComponent, children: [
                      {
                        path: '1-2-1-4', component: CtOneTwoOneFourComponent, children: [
                          { path: '2021-22', component: C1214TwentyoneComponent },
                          { path: '2020-21', component: C1214TwentyComponent },
                          { path: '2019-20', component: C1214NineteenComponent },
                          { path: '2018-19', component: C1214EighteenComponent },
                          { path: '2017-18', component: C1214SeventeenComponent },
                        ]
                      }
                    ]
                  },
                  {
                    path: '1-2-2', component: CtOneTwoTwoComponent, children: [
                      { path: '1-2-2-2', component: CtOneTwoTwoTwoComponent },
                      { path: '1-2-2-3', component: CtOneTwoTwoThreeComponent },
                      {
                        path: '1-2-2-4', component: CtOneTwoTwoFourComponent, children: [
                          { path: '2021-22', component: C1224TwentyoneComponent },
                          { path: '2020-21', component: C1224TwentyComponent },
                          { path: '2019-20', component: C1224NineteenComponent },
                          { path: '2018-19', component: C1224EighteenComponent },
                          { path: '2017-18', component: C1224SeventeenComponent },
                        ]
                      },
                    ]
                  },
                  {
                    path: '1-3-2', component: CtOneThreeTwoComponent, children: [
                      { path: '1-3-2-3', component: CtOneThreeTwoThreeComponent }
                    ]
                  },
                  { path: '1-4-1', component: CtOneFourOneComponent },
                ]
              },
              {
                path: 'criteria3', component: CriteriaThreeComponent, children: [
                  {
                    path: '3-2-2', component: CtThreeTwoTwoComponent, children: [
                      { path: '2021-22', component: C32TwentyoneComponent },
                      { path: '2020-21', component: C32TwentyComponent },
                      { path: '2019-20', component: C32NineteenComponent },
                    ]
                  },
                  {
                    path: '3-3-1', component: CtThreeThreeOneComponent, children: [
                      {
                        path: '3-3-1-1', component: CtThreeThreeOneOneComponent, children: [
                          { path: '2021-22', component: C331TwentyoneComponent },
                          { path: '2020-21', component: C331TwentyComponent },
                          { path: '2019-20', component: C331NineteenComponent },
                          { path: '2018-19', component: C331EighteenComponent },
                          { path: '2017-18', component: C331SeventeenComponent },
                        ]
                      }
                    ]
                  },
                  {
                    path: '3-3-2', component: CtThreeThreeTwoComponent, children: [
                      {
                        path: '3-3-2-1', component: CtThreeThreeTwoOneComponent, children: [
                          { path: '2021', component: C33TwentyoneComponent },
                          { path: '2020', component: C33TwentyComponent },
                          { path: '2019', component: C33NineteenComponent },
                          { path: '2017', component: C33SeventeenComponent },
                        ]
                      },
                    ]
                  },
                  {
                    path: '3-4-3-1', component: CtThreeFourThreeOneComponent, children: [
                      {
                        path: 'Activities-Reports', component: ActivityReportComponent, children: [
                          { path: '2021-22', component: C3TwentyoneTwentytwoComponent },
                          { path: '2020-21', component: C3TwentyTwentyoneComponent },
                          { path: '2019-20', component: C3NineteenTwentyComponent },
                          { path: '2018-19', component: C3EighteenNineteenComponent },
                        ]
                      },
                      {
                        path: 'Activities-Photos', component: ActivityPhotosComponent, children: [
                          { path: '2021-22', component: C34TwentyoneTwentytwoComponent },
                          { path: '2020-21', component: C34TwentyTwentyoneComponent },
                          { path: '2019-20', component: C34NineteenTwentyComponent },
                          { path: '2018-19', component: C34EighteenNineteenComponent },
                        ]
                      },
                    ]
                  },
                ]
              },
              {
                path: 'criteria2', component: CriteriaTwoComponent, children: [
                  { path: '2-1-1', component: CtTwoOneOneComponent },
                  { path: '2-1-2', component: CtTwoOneTwoComponent },
                  { path: '2-2-1', component: CtTwoTwoOneComponent },
                  { path: '2-4-1', component: CtTwoFourOneComponent },
                  { path: '2-4-2', component: CtTwoFourTwoComponent },
                  { path: '2-6-3', component: CtTwoSixThreeComponent },
                ]
              },
              {
                path: 'criteria4', component: CriteriaFourComponent, children: [
                  { path: '4-1-1', component: CtFourOneOneComponent }
                ]
              },
              {
                path: 'criteria5', component: CriteriaFiveComponent, children: [
                  { path: '5-1-1', component: CtFiveOneOneComponent },
                  { path: '5-1-2', component: CtFiveOneTwoComponent },
                  { path: '5-1-3', component: CtFiveOneThreeComponent },
                  { path: '5-1-4', component: CtFiveOneFourComponent },
                  { path: '5-2-1', component: CtFiveTwoOneComponent },
                  { path: '5-2-2', component: CtFiveTwoTwoComponent },
                  { path: '5-3-1', component: CtFiveThreeOneComponent },
                  { path: '5-3-2', component: CtFiveThreeTwoComponent }
                ]
              },
              {
                path: 'criteria6', component: CriteriaSixComponent, children: [
                  { path: '6-2-2', component: CtSixTwoTwoComponent },
                  { path: '6-3-2', component: CtSixThreeTwoComponent },
                  { path: '6-3-3', component: CtSixThreeThreeComponent },
                  { path: '6-5-2', component: CtSixFiveTwoComponent },
                ]
              },
              {
                path: 'criteria7', component: CriteriaSevenComponent, children: [
                  { path: '7-1-2', component: CtSevenOneTwoComponent },
                  { path: '7-1-3', component: CtSevenOneThreeComponent }
                ]
              }
            ]
          },
          {path:'aqar-report', component:AqarComponent},
          // {
            // path: '', component: AqarComponent, children: [
            //   { path: '2015-16', component: AqOnefiveOnesixComponent },
            //   { path: '2016-17', component: AqOnesixOnesevenComponent },
            //   { path: '2017-18', component: AqOnesevenOneeightComponent },
            //   { path: '2018-19', component: AqOneeightOnenineComponent },
            //   { path: '2019-20', component: AqOnenineTwozeroComponent },
            //   { path: '2020-21', component: AqTwozeroTwooneComponent },
            //   { path: '2021-22', component: AqTwooneTwotwoComponent },
            //   { path: '2022-23', component: AqTwotwoTwothreeComponent },
              {
                path: 'aqar/2023-24', component: TwothreeTwofourComponent, children: [
                  { path: 'extended-profile', component: Aqar23ProfileComponent },
                  {
                    path: 'criteria1', component: Aqar23C1Component, children: [
                      { path: '1-3-2', component: Aqar23C1OneThreeTwoComponent },
                      { path: '1-4-2', component: Aqar23C1OneFourTwoComponent },
                    ]
                  },
                  {
                    path: 'criteria2', component: Aqar23C2Component, children: [
                      { path: '2-7-1', component: Aqar23C2TwoSevenOneComponent },
                    ]
                  },
                  {
                    path: 'criteria3', component: Aqar23C3Component, children: [
                      { path: '3-2-2', component: Aqar23C3ThreeTwoTwoComponent },
                      { path: '3-5-1', component: Aqar23C3ThreeFiveOneComponent },
                    ]
                  },
                  {
                    path: 'criteria5', component: Aqar23C5Component, children: [
                      { path: '5-1-1', component: Aqar23C5FiveOneOneComponent },
                      { path: '5-1-3', component: Aqar23C5FiveOneThreeComponent },
                    ]
                  },
                  {
                    path: 'criteria6', component: Aqar23C6Component, children: [
                      { path: '6-1-2', component: Aqar23C6SixOneTwoComponent },
                    ]
                  },
                  {
                    path: 'criteria7', component: Aqar23C7Component, children: [
                      { path: '7-1-1', component: Aqar23C7SevenOneOneComponent },
                      { path: '7-2-1', component: Aqar23C7SevenTwoOneComponent },
                      { path: '7-1-9', component: Aqar23C7SevenOneNineComponent },
                      { path: '7-3-1', component: Aqar23C7SevenThreeOneComponent },
                    ]
                  },
                ]
              }
            // ]
          // }
        ]
      }
    ])
  ]
})
export class NaacModule { }
