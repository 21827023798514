import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-aq-twozero-twoone',
  templateUrl: './aq-twozero-twoone.component.html',
  styleUrls: ['./aq-twozero-twoone.component.scss']
})
export class AqTwozeroTwooneComponent implements OnInit {
  src: string ='/assets/pdf/AQAR-2020-21.pdf';
  constructor() { }

  ngOnInit(): void {
  }

}
