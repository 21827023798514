import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-aqar23-c7-seven-one-nine',
  templateUrl: './aqar23-c7-seven-one-nine.component.html',
  styleUrls: ['./aqar23-c7-seven-one-nine.component.scss']
})
export class Aqar23C7SevenOneNineComponent implements OnInit {
  src: string ='/assets/pdf/NAAC/AQAR/7.1.9_Activities to Inculcate Values in Students to make tham Responsible Citizens.pdf';
  constructor() { }

  ngOnInit(): void {
  }

}
