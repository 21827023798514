import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-aq-oneseven-oneeight',
  templateUrl: './aq-oneseven-oneeight.component.html',
  styleUrls: ['./aq-oneseven-oneeight.component.scss']
})
export class AqOnesevenOneeightComponent implements OnInit {
  src: string ='/assets/pdf/NAAC_2017-18.pdf';
  constructor() { }

  ngOnInit(): void {
  }

}
