import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-aqar23-c2-two-seven-one',
  templateUrl: './aqar23-c2-two-seven-one.component.html',
  styleUrls: ['./aqar23-c2-two-seven-one.component.scss']
})
export class Aqar23C2TwoSevenOneComponent implements OnInit {
  src: string ='/assets/pdf/NAAC/AQAR/2.7.1_Student satisfaction survey 2023-24.pdf';
  constructor() { }

  ngOnInit(): void {
  }

}
