import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-aq-twoone-twotwo',
  templateUrl: './aq-twoone-twotwo.component.html',
  styleUrls: ['./aq-twoone-twotwo.component.scss']
})
export class AqTwooneTwotwoComponent implements OnInit {
  src: string ='/assets/pdf/AQAR_2021-22.pdf';
  constructor() { }

  ngOnInit(): void {
  }

}
