import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-aqar23-c3',
  templateUrl: './aqar23-c3.component.html',
  styleUrls: ['./aqar23-c3.component.scss']
})
export class Aqar23C3Component implements OnInit {
@HostListener("click") onClick() {
    this.checkRoute();
  }
  fullWidth = false;
  showSideMenu = false;
  constructor() { }

  ngOnInit(): void {
    this.checkRoute();
  }

  splitUrl() {
    let url = location.pathname.split('/');
    if (url.length === 5 || (url.length === 6 && (url[5] === '3-2-2' || url[5] === '3-5-1'))) {
      this.showSideMenu = true;
      this.fullWidth = false;
    }
    else {
      this.showSideMenu = false;
      this.fullWidth = true;
    }
  }
  checkRoute() {
    setTimeout(() => {
      this.splitUrl();
    })
  }
}
