import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-aqar23-c2',
  templateUrl: './aqar23-c2.component.html',
  styleUrls: ['./aqar23-c2.component.scss']
})
export class Aqar23C2Component implements OnInit {
@HostListener("click") onClick() {
    this.checkRoute();
  }
  fullWidth = false;
  showSideMenu = false;
  constructor() { }

  ngOnInit(): void {
    this.checkRoute();
  }

  splitUrl() {
    let url = location.pathname.split('/');
    if (url.length === 5 || (url.length === 6 && url[5] === '2-7-1')) {
      this.showSideMenu = true;
      this.fullWidth = false;
    }
    else {
      this.showSideMenu = false;
      this.fullWidth = true;
    }
  }
  checkRoute() {
    setTimeout(() => {
      this.splitUrl();
    })
  }
}
